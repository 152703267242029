import React,{ useState, useEffect } from 'react'
import style from './Syllabus.module.css'
import { useNavigate } from "react-router-dom";
const Syllabus = () => {
  const navigate=useNavigate()
  const key =JSON.parse(localStorage.getItem("access-token"));
  async function syllabusget(){
    await fetch('https://services.1o1learnersclub.com/syllabus/get',{
      method:'POST',
      headers:{
        "Content-Type": "application/json",
                "Authorization":`Bearer ${key}`
      }
    }).then((res)=>res.json()).then((data)=>{
      console.log(data);
    })
  }
  useEffect(()=>{
    // syllabusget()
  },[])
  return (
    <div className={style.Container}>
      <div className={style.Syllubus}>
        <div className={style.Cards}>
        <div className={style.cardContent}>
            <p>Title  :  WebApp</p>
            <p>Goal :  React Developer</p>
          </div>
        </div>
        <div className={style.Title}><p>Syllubus</p></div>
        <div className={style.List}>
          <ol type="1">
            <li onClick={()=>{
              navigate("/lesson")
            }}>python Programing</li>
            <li>Data Types</li>
            <ol type="a">
              <li>Integer</li>
              <li>String</li>
            </ol>
            <li>Data Structures</li>
            <li>Variables</li>
            <li>List/Collections</li>
            <li>Loops</li>
            <li>Conditional Statements</li>
            <li>Class & Objects</li>
          </ol>
        </div>
      </div>

    </div>
  )
}

export default Syllabus