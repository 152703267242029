import React,{useState} from 'react'
import style from './Flip.module.css'

const Flip = () => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleMouseEnter = () => {
      setIsFlipped(true);
    };
  
    const handleMouseLeave = () => {
      setIsFlipped(false);
    };
   


  return (
    <div className={style.Container}>
    <div
        className={`${style.Card} ${isFlipped ? style.Flipped : ''}`}
        onMouseEnter={() => setIsFlipped(true)}
        onMouseLeave={() => setIsFlipped(false)}
    >
        <div className={style.Front}>
            <p>1. 4,__,__,16</p>
        </div>
        <div className={style.Back}>
            <p>1. 4,8,12,16</p>
        </div>
    </div>
</div>
  )
}

export default Flip