import React, { useRef, useEffect, useState } from 'react';
import style from "./WhiteBoard.module.css"

const Whiteboard = () => {
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [color, setColor] = useState('#000000'); // Default color
  const [brushSize, setBrushSize] = useState(5); // Default brush size
  const [eraserMode, setEraserMode] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.strokeStyle = color; // Set initial drawing color
    context.lineWidth = brushSize; // Set initial brush size

    const handleMouseDown = (event) => {
      setDrawing(true);
      const bounds = canvas.getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;
      context.beginPath();
      context.moveTo(x, y);
    };

    const handleMouseMove = (event) => {
      if (!drawing) return;
      const bounds = canvas.getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;
      if (eraserMode) {
        context.clearRect(x - brushSize / 2, y - brushSize / 2, brushSize, brushSize);
      } else {
        context.lineTo(x, y);
        context.stroke();
      }
    };

    const handleMouseUp = () => {
      setDrawing(false);
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
    };
  }, [drawing, eraserMode, brushSize, color]);

  const handleColorChange = (event) => {
    setColor(event.target.value);
    setEraserMode(eraserMode?setEraserMode(false):"")
  };

  const handleBrushSizeChange = (event) => {
    setBrushSize(parseInt(event.target.value));
  };

  const toggleEraserMode = () => {
    setEraserMode(!eraserMode);
  };

  return (
    <div>
      <div>
        <input type="color" value={color} onChange={handleColorChange} />
        <input type="range" min="1" max="50" value={brushSize} onChange={handleBrushSizeChange} />
        <button className={style.EraseButton} onClick={toggleEraserMode}>{eraserMode ? 'Disable Eraser' : 'Enable Eraser'}</button>
      </div>
      <canvas
          ref={canvasRef}
          width={900}
          height={435}
          style={{ border: '1px solid #dbdbdb',boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        />

    </div>
  );
};

export default Whiteboard;
