import React, { useEffect, useState } from "react";
import style from "./Sidebar.module.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { FiPower } from "react-icons/fi";
import { LuGraduationCap } from "react-icons/lu";
import { BiUserCircle } from "react-icons/bi";
import { LuNetwork } from "react-icons/lu";
import { MdOutlineEvent, MdOutlineClose } from "react-icons/md";
import learnersLOgo from "../../assests/duno club  title logo inverse.png";
import { googleLogout } from "@react-oauth/google";
import { HiMenu } from "react-icons/hi";
import { RiBillLine } from "react-icons/ri";
import { MdQuiz, MdEventAvailable } from "react-icons/md";
import { TbBulb ,TbAward} from "react-icons/tb";

const Sidebar = () => {
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const pathname = location.pathname;
  useEffect(() => {
    if (mentor === "true") {
      if (pathname === "/learnings") {
        setActiveMenuItem("support");
      } else if (pathname === "/lerning/detail") {
        setActiveMenuItem("support");
      } else if (pathname === "/singletopic") {
        setActiveMenuItem("support");
      } else if (pathname === "/answers") {
        setActiveMenuItem("support");
      } else if (pathname === "/usercalendar") {
        setActiveMenuItem("support");
      } else if (pathname === "/profile") {
        setActiveMenuItem("profile");
      } else if (pathname === "/support") {
        setActiveMenuItem("support");
      } else if (pathname === "/peerprofile") {
        setActiveMenuItem("support");
      }
    } else {
      if (pathname === "/") {
        setActiveMenuItem("dashboard");
      } else if (pathname === "/calendar") {
        setActiveMenuItem("calendar");
      } else if (pathname === "/learnings") {
        setActiveMenuItem(
          location.state && location.state.source === "support"
            ? "support"
            : "learnings"
        );
      } else if (pathname === "/support") {
        setActiveMenuItem("support");
      } else if (pathname === "/profile") {
        setActiveMenuItem("profile");
      } else if (pathname === "/lerning/detail") {
        setActiveMenuItem(
          location.state && location.state.source === "support"
            ? "support"
            : "learnings"
        );
      } else if (pathname === "/singletopic") {
        setActiveMenuItem(
          location.state && location.state.source === "support"
            ? "support"
            : "learnings"
        );
      } else if (pathname === "/answers") {
        setActiveMenuItem(
          location.state && location.state.source === "support"
            ? "support"
            : "learnings"
        );
      } else if (pathname === "/category") {
        setActiveMenuItem("learnings");
      } else if (pathname === "/transactionhistory") {
        setActiveMenuItem("profile");
      } else if (pathname === "/usercalendar") {
        setActiveMenuItem("support");
      } else if (pathname === "/peerprofile") {
        setActiveMenuItem("support");
      } else if (pathname === "/billing") {
        setActiveMenuItem("billing");
      } else if (pathname === "/exams") {
        setActiveMenuItem("learnings");
      } else if (pathname === "/quiz") {
        setActiveMenuItem("quiz");
      } else if (pathname === "/quiz/questions") {
        setActiveMenuItem("quiz");
      }else if (pathname === "/opportunity") {
        setActiveMenuItem("opportunity");
      }else if (pathname === "/opportunity/details") {
        setActiveMenuItem("opportunity");
      }
      
    }
  }, [location.pathname]);

  // const key=localStorage.getItem("access-token")
  const mentor = localStorage.getItem("mentor");
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("access-token");
    localStorage.removeItem("ClubID");
    localStorage.removeItem("ORGID");
    localStorage.removeItem("USERID");
    localStorage.removeItem("mentor");
    localStorage.removeItem("learnertoken");
    localStorage.removeItem("learneruserid");
    localStorage.removeItem("username");
    localStorage.removeItem("newLearningId");
    localStorage.removeItem("MainLevelId");
    localStorage.removeItem("SubLevelId");
    localStorage.removeItem("clickedItem");
    localStorage.removeItem("clickeditemDes");
    localStorage.removeItem("functionCalled");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("countrydetails");
    localStorage.removeItem("timezone");
    localStorage.removeItem("currency");
    localStorage.removeItem("adminmapped");
    googleLogout();
    navigate("/login");
    window.location.reload();
  };
  useEffect(() => {
    console.log(mentor);
  }, [mentor]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the sidebar state
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <div className={style.HiMenu} onClick={toggleSidebar}>
        <HiMenu />
      </div>
      {logout ? (
        <div className={style.Popup}>
          <div className={style.popupText}>
            <p>Are you sure you want to logout?</p>
          </div>
          <div className={style.PopupButton}>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleLogout(e);
              }}
            >
              YES
            </button>
            <button
              onClick={() => {
                setLogout(false);
              }}
            >
              NO
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={style.Sidebar}>
        <div className={style.Header}>
          <div className={style.Image}>
            <img src={learnersLOgo} />
          </div>
        </div>
        <div className={style.Items}>
          {mentor === true || mentor === "true" ? (
            <>
              <div
                onClick={() => {
                  navigate("/");
                  setActiveMenuItem("dashboard");
                }}
                className={`${style.Menu} ${
                  activeMenuItem === "dashboard" ? style.ActiveMenuItem : ""
                }`}
              >
                <div className={style.Icons}>
                  <LuLayoutDashboard />
                </div>

                <p>Home</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "calendar" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/calendar");
                  setActiveMenuItem("calendar");
                }}
              >
                <div className={style.Icons}>
                  <MdOutlineEvent />
                </div>

                <p>Calendar</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "support" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/support");
                  setActiveMenuItem("support");
                }}
              >
                <div className={style.Icons}>
                  <LuNetwork />
                </div>

                <p>My Network</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "profile" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/profile");
                  setActiveMenuItem("profile");
                }}
              >
                <div className={style.Icons}>
                  <BiUserCircle />
                </div>

                <p>Profile</p>
              </div>

              <div
                className={`${style.Menu} ${
                  activeMenuItem === "logout" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  setLogout(true);
                  // setActiveMenuItem("logout");
                }}
              >
                <div className={style.Icons}>
                  <FiPower />
                </div>

                <p>Logout</p>
              </div>
            </>
          ) : (
            <>
              <div
                onClick={() => {
                  navigate("/");
                  setActiveMenuItem("dashboard");
                }}
                className={`${style.Menu} ${
                  activeMenuItem === "dashboard" ? style.ActiveMenuItem : ""
                }`}
              >
                <div className={style.Icons}>
                  <LuLayoutDashboard />
                </div>

                <p>Home</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "learnings" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/learnings");
                  setActiveMenuItem("learnings");
                }}
              >
                <div className={style.Icons}>
                  <LuGraduationCap />
                </div>

                <p>My Learnings</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "support" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/support");
                  setActiveMenuItem("support");
                }}
              >
                <div className={style.Icons}>
                  <LuNetwork />
                </div>

                <p>Support Network</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "calendar" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/calendar");
                  setActiveMenuItem("calendar");
                }}
              >
                <div className={style.Icons}>
                  <MdOutlineEvent />
                </div>

                <p>Calendar</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "profile" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/profile");
                  setActiveMenuItem("profile");
                }}
              >
                <div className={style.Icons}>
                  <BiUserCircle />
                </div>

                <p>Profile</p>
              </div>
              <div
                className={`${style.Menu} ${
                  activeMenuItem === "quiz" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/quiz");
                  setActiveMenuItem("quiz");
                }}
              >
                <div className={style.Icons}>
                  <MdQuiz />
                </div>

                <p>Quiz</p>
              </div>

              <div
                className={`${style.Menu} ${
                  activeMenuItem === "events" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/events");
                  setActiveMenuItem("events");
                }}
              >
                <div className={style.Icons}>
                  <MdEventAvailable />
                </div>

                <p>Events</p>
              </div>

              <div
                className={`${style.Menu} ${
                  activeMenuItem === "opportunity" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/opportunity");
                  setActiveMenuItem("opportunity");
                }}
              >
                <div className={style.Icons}>
                  <TbAward />
                </div>

                <p>Opportunity</p>
              </div>

              <div
                className={`${style.Menu} ${
                  activeMenuItem === "forum" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/forum");
                  setActiveMenuItem("forum");
                }}
              >
                <div className={style.Icons}>
                  <TbBulb />
                </div>

                <p>Forum</p>
              </div>

              <div
                className={`${style.Menu} ${
                  activeMenuItem === "billing" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  navigate("/billing");
                  setActiveMenuItem("billing");
                }}
              >
                <div className={style.Icons}>
                  <RiBillLine />
                </div>

                <p>Billing</p>
              </div>

              <div
                className={`${style.Menu} ${
                  activeMenuItem === "logout" ? style.ActiveMenuItem : ""
                }`}
                onClick={() => {
                  setLogout(true);
                  // setActiveMenuItem("logout");
                }}
              >
                <div className={style.Icons}>
                  <FiPower />
                </div>

                <p>Logout</p>
              </div>
            </>
          )}
        </div>
      </div>
      {isSidebarOpen ? (
        <div className={style.MobileSidebarContainer}>
          <div
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            className={style.SidebarOverlay}
          ></div>
          <div className={style.MobileSidebar}>
            <div className={style.Header}>
              <div className={style.Image}>
                <img src={learnersLOgo} />
              </div>
            </div>
            <div className={style.Items}>
              {mentor === true || mentor === "true" ? (
                <>
                  <div
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/");
                      setActiveMenuItem("dashboard");
                    }}
                    className={`${style.Menu} ${
                      activeMenuItem === "dashboard" ? style.ActiveMenuItem : ""
                    }`}
                  >
                    <div className={style.Icons}>
                      <LuLayoutDashboard />
                    </div>

                    <p>Home</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "calendar" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/calendar");
                      setActiveMenuItem("calendar");
                    }}
                  >
                    <div className={style.Icons}>
                      <MdOutlineEvent />
                    </div>

                    <p>Calendar</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "support" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/support");
                      setActiveMenuItem("support");
                    }}
                  >
                    <div className={style.Icons}>
                      <LuNetwork />
                    </div>

                    <p>My Network</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "profile" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/profile");
                      setActiveMenuItem("profile");
                    }}
                  >
                    <div className={style.Icons}>
                      <BiUserCircle />
                    </div>

                    <p>Profile</p>
                  </div>

                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "logout" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      setLogout(true);
                      // setActiveMenuItem("logout");
                    }}
                  >
                    <div className={style.Icons}>
                      <FiPower />
                    </div>

                    <p>Logout</p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/");
                      setActiveMenuItem("dashboard");
                    }}
                    className={`${style.Menu} ${
                      activeMenuItem === "dashboard" ? style.ActiveMenuItem : ""
                    }`}
                  >
                    <div className={style.Icons}>
                      <LuLayoutDashboard />
                    </div>

                    <p>Home</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "learnings" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/learnings");
                      setActiveMenuItem("learnings");
                    }}
                  >
                    <div className={style.Icons}>
                      <LuGraduationCap />
                    </div>

                    <p>My Learnings</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "support" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/support");
                      setActiveMenuItem("support");
                    }}
                  >
                    <div className={style.Icons}>
                      <LuNetwork />
                    </div>

                    <p>Support Network</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "calendar" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/calendar");
                      setActiveMenuItem("calendar");
                    }}
                  >
                    <div className={style.Icons}>
                      <MdOutlineEvent />
                    </div>

                    <p>Calendar</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "profile" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/profile");
                      setActiveMenuItem("profile");
                    }}
                  >
                    <div className={style.Icons}>
                      <BiUserCircle />
                    </div>

                    <p>Profile</p>
                  </div>
                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "billing" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      navigate("/billing");
                      setActiveMenuItem("billing");
                    }}
                  >
                    <div className={style.Icons}>
                      <RiBillLine />
                    </div>

                    <p>Billing</p>
                  </div>

                  <div
                    className={`${style.Menu} ${
                      activeMenuItem === "logout" ? style.ActiveMenuItem : ""
                    }`}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      setLogout(true);
                    }}
                  >
                    <div className={style.Icons}>
                      <FiPower />
                    </div>

                    <p>Logout</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sidebar;
