import React, { useEffect, useState,useRef } from "react";
import style from "./QuizQuestions.module.css";
import { useLocation } from "react-router-dom";
import { baseurl } from "../Url";
import { HiSpeakerWave } from "react-icons/hi2";
import Lottie from "lottie-react";
import { AnimationData } from "../../Animation/AnimationData";

const QuizQuestions = () => {
  const location = useLocation();
  const state = location?.state;
  const quizId = state?.quizID;
  const quizName=state?.quizName

  const OrgID = localStorage.getItem("ORGID");
  const USERID = localStorage.getItem("USERID");

  const [quizData, setQuizData] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [draggedItem, setDraggedItem] = useState(null);
  const [error, setError] = useState("");
  const [answers, setAnswers] = useState([]);
  const [timerStarted, setTimerStarted] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [isTextareaDisabled, setIsTextareaDisabled] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [answerDetails, setAnswerDetails] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [validatClicked, setValidateClicked] = useState(false);
  const [valid, setValid] = useState(false);
  const [cluesAcross, setCluesAcross] = useState([]);
  const [cluesDown, setCluesDown] = useState([]);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const[total,setTotal]=useState("")
  const[correct,setCorrect]=useState("")
  const[inCorrect,setIncorrect]=useState("")
  const[unAttended,setUnAttended]=useState("")
  const[totalMark,setTotalMark]=useState("")
  const[obtainedMark,setObtainedmark]=useState("")

  const inputRefs = useRef([]);

  const numbers = Array.from(
    { length: quizData.length },
    (_, index) => index + 1
  );

  let Timer;

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} `;
  };

  const handleNext = () => {
    if (answerDetails.length === 0 && answers.length > 0) {
      handleNextValidation();
    } else {
      setAnswers([]);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % quizData.length);
      setSelectedNumber((prevIndex) => prevIndex + 1);
      clearTimeout(Timer);
      setTimerExpired(false);
      setTimerStarted(false);
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
      setValid(false);
    }
    setValidateClicked(false);
  };

  const handleSkip = async () => {
    clearTimeout(Timer);
    if (timerStarted && answerDetails.length === 0) {
      await quizAnswer();
      setTimeout(() => {
        clearTimeout(Timer);
        setTimerExpired(false);
        setTimerStarted(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % quizData.length);
        setSelectedNumber((prevIndex) => prevIndex + 1);
        setIsTextareaDisabled(false);
        setAnswerDetails([]);
        setAnswers([]);
      }, 2000);
    } else {
      clearTimeout(Timer);
      setTimerExpired(false);
      setTimerStarted(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % quizData.length);
      setSelectedNumber((prevIndex) => prevIndex + 1);
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
      setAnswers([]);
      setValid(false);
    }
    setValidateClicked(false);
  };

  const handleNextValidation = async () => {
    clearTimeout(Timer);
    await quizAnswer();
    setTimeout(() => {
      clearTimeout(Timer);
      setAnswers([]);
      setTimerExpired(false);
      setTimerStarted(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % quizData.length);
      setSelectedNumber((prevIndex) => prevIndex + 1);
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
    }, 2000);
  };

  const handleCheckCircleClick = () => {
    quizAnswer();
    setIsTextareaDisabled(true);
    setIsTimerRunning(false);
    if (!timerExpired) {
      clearTimeout(Timer); // Stop the timer
    }
  };

  const handleDragStart = (e, item) => {
    if (
      currentItem.type === "Match the following" ||
      currentItem.type === "Match the following with image"
    ) {
      setDraggedItem(item);
    }
  };

  const handleDrop = (e, rowIndex) => {
    e.preventDefault();

    // Ensure that the dragged item is not dropped on itself
    if (draggedItem) {
      // Update the table data with the dropped item
      const updatedTableData = [...answers];
      updatedTableData[rowIndex] = draggedItem;

      // Update the state
      setAnswers(updatedTableData);

      // Reset draggedItem state
      setDraggedItem(null);
    }
  };

  const handleCellValueChange = (rowIndex, colIndex, value, id, clueNumber) => {
    if (answers[rowIndex][colIndex].cluevalue !== '' && value === '') {
      return;
    }
  
    const newGrid = answers.map((row, i) =>
      row.map((cell, j) =>
        i === rowIndex && j === colIndex ? { ...cell, cluevalue: value.toUpperCase() } : cell
      )
    );
    setAnswers(newGrid);
  
    // Update the corresponding across clues if found
    const updatedCluesAcross = cluesAcross.map(clue => {
      if (clue.id === id) {
        const updatedAnswer = newGrid
          .flat()
          .filter(cell => cell.clueID === id)
          .map(cell => cell.cluevalue)
          .join("");
        return { ...clue, answer: updatedAnswer };
      } else {
        return clue;
      }
    });
    setCluesAcross(updatedCluesAcross);
  
    // Update the corresponding down clues if found
    const updatedCluesDown = cluesDown.map(clue => {
      if (clue.id === id) {
        const updatedAnswer = newGrid
          .flat()
          .filter(cell => cell.clueID === id)
          .map(cell => cell.cluevalue)
          .join("");
        return { ...clue, answer: updatedAnswer };
      } else {
        return clue;
      }
    });
    setCluesDown(updatedCluesDown);
    const currentCellIndex = rowIndex * answers[0].length + colIndex;
      let nextCellIndex = currentCellIndex + 1;
      while (nextCellIndex < answers.flat().length && answers.flat()[nextCellIndex].clueID !== id) {
        nextCellIndex++;
      }
  
      // If found, focus on the input of the next cell
      if (nextCellIndex < answers.flat().length) {
        const nextInputRef = inputRefs.current[nextCellIndex];
        if (nextInputRef && nextInputRef.focus) {
          nextInputRef.focus();
        }
      }
  };

  const handleAudioClick = (audioUrl,index) => {
    if (!audioPlayer || audioPlayer.src !== audioUrl) {
      const newAudioPlayer = new Audio(audioUrl);
      newAudioPlayer.play();
      setAudioPlayer(newAudioPlayer);
      setCurrentPlayingIndex(index);
    } else {
      if (audioPlayer.paused) {
        audioPlayer.play();
      } else {
        audioPlayer.pause();
      }
    }
  };





  async function quizQuestionList() {
    await fetch(`${baseurl}/quiz/individual/list`, {
      method: "POST",
      body: JSON.stringify({
        quiz_id: quizId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setQuizData(data.data[0].questions);
      });
  }


  async function quizAnswer() {
    const questionId = currentItem.id ? currentItem.id.toString() : "";
    const calculatedTime = (parseInt(currentItem.time) * 60) - remainingTime; 
    const modifiedAnswers = answers.map(answerGroup => (
      Array.isArray(answerGroup) ? // Check if answerGroup is an array
        answerGroup.map(answer => ({
            clueID: answer.clueID,
            clueNumber: answer.clueNumber,
            cluevalue: answer.cluevalue,
        })) :
        answerGroup 
  ));
    await fetch(`${baseurl}/quiz/question/validation`, {
      method: "POST",
      body: JSON.stringify({
        quiz_id: quizId,
        org_id: OrgID,
        question: currentItem.question,
        qstn_id: questionId,
        answer:currentItem.type === 'cross word puzzle'?modifiedAnswers: answers,
        user_id: USERID,
        question_type: currentItem.type,
        options: currentItem.options ? currentItem.options : [],
        unique_id: currentItem.unique_id,
        left: currentItem.left ? currentItem.left : [],
        right: currentItem.right ? currentItem.right : [],
        time_taken:calculatedTime.toString()||"",
        point:currentItem.point||""

      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        quizSummaryDetails()
        if (data.data[0].result === "Correct") {
          setValidationSuccess(true);
        } else {
          setValidationFail(true);
        }
      });
  }


  async function quizSummaryDetails() {
    await fetch(`${baseurl}/quiz/summary`, {
      method: "POST",
      body: JSON.stringify({
        quiz_id:quizId,
        user_id:USERID
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.data.length>0){
        setTotal(data.Total_questions[0].Total_questions)
        setCorrect(data.Correct_ans[0].Correct_ans)
        setIncorrect(data.Incorrect_ans[0].Incorrect_ans)
        setUnAttended(data.Skipped_questions[0].Skipped_questions)
        
        }
        
      });
  }


  useEffect(() => {
    if (quizId) {
      quizQuestionList();
      quizSummaryDetails()
    }
  }, [quizId]);

  useEffect(() => {
    if (quizData && quizData.length > 0) {
      setCurrentItem(quizData[currentIndex]);
    }
  }, [quizData, currentIndex]);

  useEffect(() => {
    if (timerStarted && !timerExpired && remainingTime > 0) {
      Timer = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000); // 1 second
    } else if (remainingTime === 0) {
      setTimerExpired(true);
      setIsTimerRunning(false);
      // handleTimerExpiration();
    }

    return () => {
      clearTimeout(Timer);
    };
  }, [timerStarted, timerExpired, remainingTime]);

  useEffect(()=>{
    if (currentItem && currentItem.type === 'cross word puzzle') {
      const grid = currentItem.options.map(row => (
        row.map(cell => ({
          clueID:cell.clueID ||"",
          clueNumber:cell.clueNumber,
          cluevalue: '',
          disabled: cell.cluevalue === '',
        }))
      ));
      setAnswers(grid);
       // Extracting across and down clues
       const acrossClues = currentItem.left.map(clueObj => ({
        id: clueObj.id,
        number: clueObj.number,
        clue: clueObj.clue,
        answer:''
      }));
      const downClues = currentItem.right.map(clueObj => ({
        id: clueObj.id,
        number: clueObj.number,
        clue: clueObj.clue,
        answer:''
      }));
  
      setCluesAcross(acrossClues);
      setCluesDown(downClues);
    }
  
  },[currentItem])
  
  useEffect(() => {
    if (currentItem && currentItem.quiz_time) {
      const timeInSeconds = parseInt(currentItem.quiz_time) * 60; // Convert minutes to seconds
    setRemainingTime(timeInSeconds);
      setTimeout(() => {
        setTimerStarted(true);
      }, 1000);
    }
    else{
      setTimerStarted(false);
    }
    setTimerExpired(false)
  }, [currentItem]);

  useEffect(() => {
    if (validationSuccess) {
      const timeoutId = setTimeout(() => {
        setValidationSuccess(false);
      }, 3000);
      console.log(validationSuccess);
      return () => clearTimeout(timeoutId);
    }
  }, [validationSuccess]);

  useEffect(() => {
    if (validationFail) {
      const timeoutId = setTimeout(() => {
        setValidationFail(false);
      }, 3000);
      console.log(validationFail);
      return () => clearTimeout(timeoutId);
    }
  }, [validationFail]);

 
  

  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>{quizName}</p>
        </div>
        <div className={style.HeadButton}></div>
      </div>
      <div className={style.assesment}>
        {currentItem.id ? (
          <div className={style.AssessmentHead}>
            <div className={style.totalAssesment}>
              <div className={style.Count}>
                <span className={style.value}>
                  {" "}
                  Question no -{currentItem.id}/{quizData.length}
                </span>
              </div>
              <div
                className={style.assesmentQuestion}
                style={{
                  alignItems:
                    currentItem.type === "fill in the blanks" ? "normal" : "",
                }}
              >
                {currentItem.id}.
                {currentItem.type === "fill in the blanks" &&
                currentItem.generation === "admin" ? (
                  <div className={style.FillQuestion}>
                    {currentItem.question
                      .split(/\[blank \d+\]/)
                      .map((part, index) =>
                        index ===
                        currentItem.question.split(/\[blank \d+\]/).length -
                          1 ? (
                          <span
                            key={index}
                            dangerouslySetInnerHTML={{ __html: part }}
                          />
                        ) : (
                          <React.Fragment key={index}>
                            <span>{part}</span>
                            <input
                              type="text"
                              value={answers[index] || ""}
                              onChange={(e) => {
                                const newAnswers = [...answers];
                                newAnswers[index] = e.target.value;
                                setAnswers(newAnswers);
                              }}
                            />
                          </React.Fragment>
                        )
                      )}
                  </div>
                ) : currentItem.type === "fill in the blanks" ? (
                  <div className={style.FillQuestion}>
                    {currentItem.question.split(/_{2,}/).map((part, index) =>
                      index ===
                      currentItem.question.split(/_{2,}/).length - 1 ? (
                        <span
                          key={index}
                          dangerouslySetInnerHTML={{ __html: part }}
                        />
                      ) : (
                        <React.Fragment key={index}>
                          <span>{part}</span>
                          <input
                            type="text"
                            value={answers[index] || ""}
                            onChange={(e) => {
                              const newAnswers = [...answers];
                              newAnswers[index] = e.target.value;
                              setAnswers(newAnswers);
                            }}
                          />
                        </React.Fragment>
                      )
                    )}
                  </div>
                ) : currentItem.type === "Match the following" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentItem.question,
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentItem.question,
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  border:
                    currentItem.type === "multiple choices" ||
                    currentItem.type === "multiple choice" ||
                    currentItem.type === "Match the following" ||
                    (currentItem.type === "fill in the blanks" &&
                      currentItem.question.includes("__") &&
                      currentItem.options.length < 0)
                      ? "none"
                      : "",
                }}
                className={style.assesmentAnswer}
              >
                {currentItem.type === "multiple choices" ||
                currentItem.type === "multiple choice" ? (
                  currentItem.options.map((optionObj, index) => {
                    let optionValue;
                    if (typeof optionObj === "string") {
                      optionValue = optionObj; // Direct string value
                    } else if (optionObj.option) {
                      optionValue = optionObj.option; // Nested within an object with "option" key
                    } else {
                      // If options are nested within objects with unique keys
                      const optionKey = Object.keys(optionObj)[0];
                      optionValue = optionObj[optionKey];
                    }
                    return (
                      <div key={index}>
                        <input
                          type="radio"
                          id={`option_${index}`}
                          name="assessmentOptions"
                          value={optionValue}
                          checked={answers.includes(optionValue)}
                          onChange={(e) => {
                            setAnswers([e.target.value]);
                          }}
                        />
                        <label htmlFor={`option_${index}`}>{optionValue}</label>
                      </div>
                    );
                  })
                ) : currentItem.type === "fill in the blanks" &&
                  currentItem.question.includes("_") ? (
                  <></>
                ) : currentItem.type === "fill in the blanks" &&
                  !currentItem.question.includes("_") &&
                  currentItem.generation === "admin" ? (
                  <></>
                ) : currentItem.type === "fill in the blanks" &&
                  !currentItem.question.includes("_") &&
                  currentItem.generation !== "admin" ? (
                  <>
                    <textarea
                      onChange={(e) => {
                        setAnswers([e.target.value]);
                      }}
                      value={answers[0]}
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                    <div className={style.BottomIcons}></div>
                  </>
                ) : currentItem.type === "Match the following" ? (
                  <div className={style.DropContainer}>
                    <div className={style.DropQuestions}>
                      <table>
                        <tbody>
                          {currentItem.left
                            ? currentItem.left.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item}</td>
                                    <td
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleDrop(e, index)}
                                    >
                                      {answers[index] ? (
                                        <div className={style.DraggableItem}>
                                          {answers[index]}
                                        </div>
                                      ) : (
                                        <div
                                          className={style.DraggablePlaceholder}
                                        >
                                          {
                                            "Drag Answer Here"}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                    <div className={style.DropAnswers}>
                      <h5>Answers</h5>
                      <div className={style.CardContainer}>
                        {currentItem.right
                          ? currentItem.right.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  draggable
                                  onDragStart={(e) => handleDragStart(e, item)}
                                  className={style.Card}
                                >
                                  <p>{item} </p>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                ):currentItem.type==="dictation"?(
                  <div >
                    <table className={style.DictationTabel}>
                      <tbody>
                        {currentItem.left.map((url,index)=>{
                          return(
                            <tr key={index}>
                          <td><HiSpeakerWave style={{color:currentPlayingIndex===index?"green":''}} className={style.speakerIcon} onClick={() => handleAudioClick(url,index)}/></td>
                          <td><input
                              type="text"
                              placeholder="Enter your answer"
                              value={answers[index] || ""}
                              onChange={(e) => {
                                const newAnswers = [...answers];
                                newAnswers[index] = e.target.value;
                                setAnswers(newAnswers);
                              }}
                              disabled={
                                timerExpired ||
                                isTextareaDisabled 
                              }
                            /></td>
                        </tr>
                          )

                        })}
                        
                      </tbody>
                    </table>
                  </div>
                )
                 : currentItem.type === "Match the following with image" ? (
                  <div className={style.DropContainer}>
                    <div className={style.DropQuestions}>
                      <table>
                        <tbody>
                          {currentItem.left
                            ? currentItem.left.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td><div className={style.DropDownImage}>
                                    <img src={item} alt={`Option ${index + 1}`} style={{ width: "100px", height: "100px" }} />
                                      </div></td>
                                    <td
                                      onDragOver={(e) =>
                                        e.preventDefault()
                                      }
                                      onDrop={(e) => handleDrop(e, index)}
                                    >
                                      {answers[index] ? (
                                        <div
                                          className={style.DraggableItem}
                                        >
                                          {answers[index]}
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            style.DraggablePlaceholder
                                          }
                                        >
                                          {/* {draggedItem === null &&
                                            "Drag Answer Here"} */}
                                            {"Drag Answer Here"}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                    <div className={style.DropAnswers}>
                      <h5>Answers</h5>
                      <div className={style.CardContainer}>
                        {currentItem.right
                          ? currentItem.right.map((item, index) => {
                              return (
                                <div
                                  disabled={timerExpired }
                                  key={index}
                                  
                                  onDragStart={(e) =>
                                    handleDragStart(e, item)
                                  }
                                  className={style.Card}
                                >
                                  <p>{item} </p>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                ): currentItem.type === "cross word puzzle"? (
                  <div className={style.crosswordcontainer}>
                  <div className={style.crosswordpuzzle}>
                  {answers.map((row, rowIndex) => (
                    <div key={rowIndex} className={style.row}>
                      {row.map((cell, colIndex) => (
                        <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                          <input
                          ref={(input) => (inputRefs.current[rowIndex * answers[0].length + colIndex] = input)}
                            type="text"
                            maxLength="1"
                            className={`${style.cell} ${cell.disabled ? style.disabledInput : ''}`}
                            value={cell.value}
                            onChange={(e) => handleCellValueChange(rowIndex, colIndex, e.target.value,cell.clueID,cell.clueNumber)}
                            disabled={cell.disabled}
                          />
                          {cell.clueNumber && (  // Display clue number only if it exists
                            <div className={style.clueNumber}>{cell.clueNumber}</div>
                          )}
                        </div>
                      ))}
                    </div>
                    ))}
                </div>
                <div className={style.PuzzleQuestion}>
                  <div className={style.QuestionAcross}>
                  <h4>Across</h4>
                  {cluesAcross.map((clueObj, index) => (
                    <div key={index} className={style.QuestionAcrossInputContainer}>
                      <span>{clueObj.number}.{clueObj.clue} </span>
                      
                      <input type="text" value={clueObj.answer} readOnly/>
                    </div>
                  ))}
                  
                  </div>
                  <div className={style.QuestionDown}>
                  <h4>Down</h4>
                  {cluesDown.map((clueObj, index) => (
                      <div key={index} className={style.QuestionDownInputContainer}>
                        <span>{clueObj.number}.{clueObj.clue} </span>
                        <input type="text" value={clueObj.answer} readOnly/>
                        
                      </div>
                    ))}
                    
                    </div>
                </div>
                  </div>
                ) : (
                  <>
                    <textarea
                      onChange={(e) => {
                        setAnswers([e.target.value]);
                      }}
                      value={answers[0]}
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </>
                )}
              </div>
              <div className={style.BottomIcons}>
                <span>
                  {timerStarted && !timerExpired && (
                    <p>Time Remaining: {formatTime(remainingTime)} seconds</p>
                  )}
                  {timerExpired && (
                    <p style={{ color: "red" }}>Timer Expired!</p>
                  )}
                </span>
              </div>

              {error && <p className={style.Validtext}>*{error}</p>}
            </div>
            <div className={style.answer}>
              {answerDetails.length > 0 &&
                answerDetails.map((validation) => {
                  const resultStyle = {
                    color: validation.answer_is === "Correct" ? "green" : "red",
                  };
                  return (
                    <p key={validation.question_id} style={resultStyle}>
                      {validation.result}
                    </p>
                  );
                })}
            </div>
            <div className={style.AddButton}>
              <>
                <button
                  className={style.ValidateButton}
                  onClick={() => {
                    if (answers.length > 0) {
                      
                        handleCheckCircleClick();
                        setValidateClicked(true);
                      
                    } else {
                      setValid(true);
                    }
                  }}
                  disabled={validatClicked || timerExpired}
                >
                  Validate
                </button>
                {currentIndex === quizData.length - 1 ? (
                  ""
                ) : (
                  <>
                    <button
                      onClick={() => {
                        handleNext();
                      }}
                      disabled={currentIndex === quizData.length - 1}
                      className={style.FinishButton}
                    >
                      Next Question
                    </button>
                    <button
                      onClick={() => {
                        handleSkip();
                      }}
                      disabled={currentIndex === quizData.length - 1}
                      className={style.SkipButton}
                    >
                      Skip
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
        ) : (
          <div>No Assessments in mapped yet </div>
        )}

        <div className={style.AssesssmentSide}>
          <div className={style.Sidecard}>
            <div className={style.CardHead}>Question Palette:</div>
            <div className={style.Set}>
              <p>Set :1</p>
            </div>
            <div className={style.Grid}>
              {numbers.map((number) => {
                return (
                  <span
                    key={number}
                    onClick={() => {
                      setAnswers([]);
                      setCurrentIndex(number - 1);
                      setSelectedNumber(number)
                      clearTimeout(Timer);
                      setTimerExpired(false);
                      setTimerStarted(false);
                      setIsTextareaDisabled(false);
                      setAnswerDetails([]);
                      setValidateClicked(false);
                    }}
                    className={style.span}
                    // style={{ backgroundColor }}
                  >
                    {number}
                  </span>
                );
              })}
            </div>
          </div>
          <div  className={style.AssessmentSummary}>
                  <h4>Quiz Summary</h4>
                <div className={style.Marks}>
          
                <>
            {/* <div style={{background:"#007bff"}} className={style.Markdiv}> 
            <div className={style.FirstText}>Total Mark</div>:&nbsp;
            <div className={style.SecondText}>{obtainedMark||0}/{totalMark||0}</div>
            </div> */}
            <div style={{background:"#007bff"}} className={style.Markdiv}>  <div className={style.FirstText}>Correct Answer</div>:&nbsp;
            <div className={style.SecondText}>{correct||0}</div>  </div>
           <div style={{background:"#e63720"}} className={style.Markdiv}><div className={style.FirstText}>Wrong Answer</div>:&nbsp;
            <div className={style.SecondText}>{inCorrect||0}</div>  </div>
           <div style={{background:"#c2af04"}} className={style.Markdiv}> <div className={style.FirstText}>Unattended</div>:&nbsp;
            <div className={style.SecondText}>{unAttended||0}</div></div>
          </>
          
        </div>
                </div>
        </div>
      </div>
      {validationSuccess ? (
                <div
                  className={style.Overlay}
                  onClick={() => {
                    setValidationSuccess(false);
                  }}
                >
                  <div className={style.assessmentSuccessAnimation}>
                    <Lottie
                      animationData={AnimationData.success}
                      loop={false}
                      duration
                      autoplay
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {validationFail ? (
                <div
                  className={style.Overlay}
                  onClick={() => {
                    setValidationFail(false);
                  }}
                >
                  <div className={style.assessmentFailAnimation}>
                    <Lottie
                      animationData={AnimationData.fail}
                      loop={false}
                      duration
                      autoplay
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

    </div>
  );
};

export default QuizQuestions;
