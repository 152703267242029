import React from "react";
import { useEffect, useState } from "react";
import style from "./Login.module.css";
import { useNavigate,useLocation } from "react-router-dom";
import {
  AiOutlineInfoCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import learnersLOgo from "../../assests/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../actions/user";
import facebook from "../../assests/Facebook_Logo_(2019).png.webp";
import google from "../../assests/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png";
import jwt_decode from "jwt-decode";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin} from '@react-oauth/google';
import{encryptJsonData,encryptedData} from '../EncryptionUtils'
import CryptoJS from 'crypto-js';
import {encrypt,encryptdata} from '../Encryption'
import {baseurl} from '../Url'

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [eyeButton, setEyeButton] = useState("");
  const [type, setType] = useState(true);
  const[message,setmessage]=useState("")
  let {users} = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const[popup,setPopup]=useState(false)
  const[gmail,setGmail]=useState("")
  const[googleUsername,setGoogleUsername]=useState("")
  const clickedItem=localStorage.getItem("clickedItem")
  const[conditionPopup,SetConditionPopup]=useState(false)
  const newBaseKey=""
  const[email,setEmail]=useState("")
  const[EmailSend,setEmailSend]=useState(false)
  const[emailError,setEmailError]=useState("")

  async function userLogin(e) {
    e.preventDefault()
    const body={
      Username:username,
      Password:password
    }
   const newdata=JSON.stringify(body)
   const encryptstring=encryptJsonData(newdata)
  //  console.log(encryptstring,"new");
    await fetch(`${baseurl}/account/login`, {
      method: "POST",
      body: JSON.stringify({
        keys:encryptstring
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status==="success"){
            localStorage.setItem("access-token", JSON.stringify(data.data.access_token));
            localStorage.setItem("ClubID", data.data.club_id);
            localStorage.setItem("ORGID", data.data.org_id);
            localStorage.setItem("USERID", data.data.user_id);
            localStorage.setItem("mentor", data.data.is_mentor);
            localStorage.setItem("username", data.data.username);
          navigate("/")
        }
        else if(data.status==="error"||data.status==="failed"){
          if(data.message==="Login Failed. Please provide valid credentials."){
            setmessage("Invalid Credentials")
          }
          else if(data.message==="You are not authorised to access this page"
          ){
            setmessage("You are not authorised to access this page"
            )
          }
          else{
            setmessage("Please verify your email")
          }
          
        }
      });
  }
  async function googleLogin() {
  const body={
    Username:googleUsername,
        Email:gmail
  }
  const newdata=JSON.stringify(body)
  const encryptstring=encryptJsonData(newdata)
    await fetch(`${baseurl}/account/login/googleverification`, {
      method: "POST",
      body: JSON.stringify({
        keys:encryptstring
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status==="success"){
            localStorage.setItem("access-token", JSON.stringify(data.data.access_token));
            localStorage.setItem("ClubID", data.data.club_id);
            localStorage.setItem("ORGID", data.data.org_id);
            localStorage.setItem("USERID", data.data.user_id);
            localStorage.setItem("mentor", data.data.is_mentor);
            localStorage.setItem("username", data.data.username);
            if(clickedItem!==undefined&&clickedItem!==null){
              navigate("/learnings")
            }
            else{
              navigate("/")
            }
        }
        else if(data.status==="error"){
          setmessage("User not Found")
        }
      });
  }
  

  async function forgetPassWord() {
    await fetch(`${baseurl}/forgot/pwd`, {
      method: "POST",
      body: JSON.stringify({
        email:email
      }),
      headers: {
        "Content-Type": "application/json",
        
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true||data.status==="success"){
          setPopup(false)
        setEmailSend(true)
        }
        else{
          setEmailError("Didn't Find any account")
        }
        
      });
  }
  // useEffect(() => {
  //   if (localStorage.getItem("user")) {
  //     navigate("/");
  //   } else {
  //     navigate("/login");
  //   }
  // }, [dispatch]);
  // useEffect(()=>{
  //   if(users?.status==="success"){
  //     navigate("/")
  //   }
  //   else if(users?.status==="error"){
  //     // navigate("/login")
  //     console.log(users);
  //     setmessage("Invalid Credentials")
  //   }
  // },[users])
  useEffect(()=>{
if(gmail!==""&&googleUsername!==""){
  googleLogin()
}
  },[gmail])

  return (
    <div className={style.Container}>
      {popup?
      <div className={style.Popup}>
        <p>Enter your account email to receive a password reset</p>
        <input value={email} onChange={(e)=>{
         setEmail(e.target.value)
        }}></input>
        {emailError&&(<p>{emailError}</p>)}
       <div className={style.PopupButton}> <button disabled={email===""} onClick={()=>{
        forgetPassWord()
       }} > Send</button>
       <button onClick={()=>{
        setPopup(false)
       }}> Cancel</button>
       </div>
      </div>
      :''
      }
      {EmailSend?
      <div className={style.Popup}>
        <p>Please Check Your Email</p>
       <div className={style.PopupButton}> <button  onClick={()=>{
         setEmailSend(false)
       }} > OK</button>
      
       </div>
      </div>
      :''
      }
      {conditionPopup?
      <div className={style.PasswordPopup}>
      <div className={style.content}>
        <ul>
        <p>Password must include:</p>
          <li>6-20 Characters</li>
          <li>At least 1 capital letter</li>
          <li>At least 1 number</li>
          <li>At least 1 special character</li>
          <li>No spaces</li>
        </ul>
      </div>
      <div className={style.action}>
        <button
          onClick={() => {
           SetConditionPopup(false)
          }}
        >
          OK
        </button>
      </div>
    </div>
      :''}
            {/* <div className={style.left}>
        <div className={style.LeftContent}>
          <div className={style.LogoImage}>
          <img src={learnersLOgo} />
          </div>
          <div className={style.heading}>
            <p>1 on 1 Training <br/>
            to reach goals</p>
          </div>
          <div className={style.HeadingText}>
            <p>As eLearning progressed, the ability to integrate elements such as images, videos, audio and graphics</p>
          </div>
        </div>

      </div> */}
      <div className={style.right}>
      <div className={style.LogoImage}>
              <img src={learnersLOgo} />
              </div>
                <div className={style.SearchText}><p>Learn Anything</p></div>
        <div className={style.Login}>
        <div className={style.HeaderContent}>
            <div className={style.formText}>
              <p className={style.text1}>Sign In</p>
            <p className={style.text2}>1o1LearnersClub</p></div>

          </div>
          <form className={style.form} onSubmit={userLogin} autoComplete="off">
          <div className={style.Google}>
            <GoogleLogin
              onSuccess={credentialResponse => {
               var decode=jwt_decode(credentialResponse.credential)
                console.log("success");
                setGmail(decode.email)
                setGoogleUsername(decode.name)
                  }}
                 onError={() => {
                      console.log('Login Failed');
                        }}
                     />
            </div>
            <div className={style.Bottemtext} >
              <p className={style.bottemtext1}>OR</p>
              {/* <p className={style.bottemtext2}>Log in With</p> */}
            </div>
            {message&&username&&password?<div className={style.Error}><p>{message}</p></div>:""}
            <input
              className={style.Input}
              value={username}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              type="text"
              required
              placeholder="Username"
            />
            <div className={style.password}>
              <div onClick={()=>{
                SetConditionPopup(true)
              }} className={style.info}>
                <AiOutlineInfoCircle/>
              </div>
              <input
                className={style.passwordInput}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type={type?"password":"text"}
                placeholder="Password"
                required
              />
              <div
                className={style.eye}
                onClick={() => {
                  type ? setType(false) : setType(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </div>
            <p onClick={()=>{
              setPopup(true)
            }} style={{color:"#3b82f6"}}>Forgot Password</p>
           <div className={style.formButton}>
              <button
              disabled={username===""||password===""}
                type="submit"
              >
                Sign In
              </button>
           </div>
            {/* <div className={style.Bottemtext} >
              <p className={style.bottemtext1}>OR</p>
              <p className={style.bottemtext2}>Log in With</p>
            </div>
            <div className={style.Bottomicons}>
            <div className={style.Facebook}>
              <img src={facebook} />
            </div>
            <div className={style.Google}>
              <img src={google} />
            </div>
            </div> */}
           
            <span className={style.AccountText}>
            Don't have an Account? <a href="/register">Sign Up</a>
          </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
