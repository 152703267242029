import React, { useEffect, useState,useRef } from "react";
import style from "./Exams.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import { AiOutlineClose } from "react-icons/ai";
import { BiClipboard } from "react-icons/bi";
import Whiteboard from '../WhiteBoard/WhiteBoard';
import { HiSpeakerWave } from "react-icons/hi2";

const Exams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const examID = state?.exam_id;
  const examName = state?.examname;
  const peerRole = state?.peerRole;

  const OrgID = localStorage.getItem("ORGID");
  const USERID = localStorage.getItem("USERID");
  const learneruserid = localStorage.getItem("learneruserid");
  const ClubID = localStorage.getItem("ClubID");

  const clubIdsArray = ClubID.split(",");

  const inputRefs = useRef([]);

  const [currentItem, setCurrentItem] = useState({});
  const [array, setArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [examQuestions, setExamQuestions] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [user_ID, setUser_ID] = useState(
    peerRole !== undefined ? learneruserid : USERID
  );
  const [topics, setTopics] = useState([]);
  const [mainTopicID, setMainTopicID] = useState("");
  const [validationClicked, setValidationClicked] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);
  const[saveButtonClicked,setSaveButtonClicked]=useState(false)
  const[error,setError]=useState("")
  const[whiteBoradShow,setWhiteBoardShow]=useState(false)
  const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)
  const [closing, setClosing] = useState(false);
  const [cluesAcross, setCluesAcross] = useState([]);
  const [cluesDown, setCluesDown] = useState([]);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

  
  const numbers = Array.from({ length: array.length }, (_, index) => index + 1);


  const handleNext = () => {
    setAnswers([]);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
  };

  const handleSkip = async () => {
    setAnswers([]);

    setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
  };

  const handleDragStart = (e, item) => {
    if (currentItem.type === "Match the following") {
      setDraggedItem(item);
    }
  };
  const handleDrop = (e, rowIndex) => {
    e.preventDefault();

    // Ensure that the dragged item is not dropped on itself
    if (draggedItem) {
      // Update the table data with the dropped item
      const updatedTableData = [...answers];
      updatedTableData[rowIndex] = draggedItem;

      // Update the state
      setAnswers(updatedTableData);

      // Reset draggedItem state
      setDraggedItem(null);
    }
  };

  const handleCellValueChange = (rowIndex, colIndex, value, id, clueNumber) => {
    if (answers[rowIndex][colIndex].cluevalue !== '' && value === '') {
      return;
    }
  
    const newGrid = answers.map((row, i) =>
      row.map((cell, j) =>
        i === rowIndex && j === colIndex ? { ...cell, cluevalue: value.toUpperCase() } : cell
      )
    );
    setAnswers(newGrid);
  
    // Update the corresponding across clues if found
    const updatedCluesAcross = cluesAcross.map(clue => {
      if (clue.id === id) {
        const updatedAnswer = newGrid
          .flat()
          .filter(cell => cell.clueID === id)
          .map(cell => cell.cluevalue)
          .join("");
        return { ...clue, answer: updatedAnswer };
      } else {
        return clue;
      }
    });
    setCluesAcross(updatedCluesAcross);
  
    // Update the corresponding down clues if found
    const updatedCluesDown = cluesDown.map(clue => {
      if (clue.id === id) {
        const updatedAnswer = newGrid
          .flat()
          .filter(cell => cell.clueID === id)
          .map(cell => cell.cluevalue)
          .join("");
        return { ...clue, answer: updatedAnswer };
      } else {
        return clue;
      }
    });
    setCluesDown(updatedCluesDown);
    const currentCellIndex = rowIndex * answers[0].length + colIndex;
      let nextCellIndex = currentCellIndex + 1;
      while (nextCellIndex < answers.flat().length && answers.flat()[nextCellIndex].clueID !== id) {
        nextCellIndex++;
      }
  
      // If found, focus on the input of the next cell
      if (nextCellIndex < answers.flat().length) {
        const nextInputRef = inputRefs.current[nextCellIndex];
        if (nextInputRef && nextInputRef.focus) {
          nextInputRef.focus();
        }
      }
  };

  const handleAudioClick = (audioUrl,index) => {
    if (!audioPlayer || audioPlayer.src !== audioUrl) {
      const newAudioPlayer = new Audio(audioUrl);
      newAudioPlayer.play();
      setAudioPlayer(newAudioPlayer);
      setCurrentPlayingIndex(index);
    } else {
      if (audioPlayer.paused) {
        audioPlayer.play();
      } else {
        audioPlayer.pause();
      }
    }
  };

  const handleSaveAnswer = () => {
    // Copy the existing examQuestions array
    const updatedExamQuestions = [...examQuestions];
    // Find the index of the current question in examQuestions array
    const questionIndex = updatedExamQuestions.findIndex(
      (item) => item.unique_id === currentItem.unique_id
    );
    // If question is not found, add it to examQuestions array
    if (questionIndex === -1) {
      updatedExamQuestions.push({
        question: currentItem.question,
        type: currentItem.type,
        unique_id: currentItem.unique_id,
        user_ans: answers,
        generation: "admin",
        options: currentItem.options || [],
      });
    } else {
      // If question is found, update its user_ans array
      updatedExamQuestions[questionIndex].user_ans = answers;
    }
    // Update the state with the updated examQuestions array
    setExamQuestions(updatedExamQuestions);
  };

  
  
  async function examsGet() {
    await fetch(`${baseurl}/exam/question/fetch`, {
      method: "POST",
      body: JSON.stringify({
        exam_id: examID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setArray(data.data[0]?.questions);
          setTopics(data.data[0]?.topics);
          setMainTopicID(data.data[0]?.main_topic_id);
        }
      });
  }
  async function examsValidation() {
    await fetch(`${baseurl}/exam/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: user_ID,
        topic_id: topics,
        main_topic_id: mainTopicID,
        exam_id: examID,
        club_id: clubIdsArray,
        exam_questions: examQuestions,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setValidateLoader(false);
          navigate("/exam/summary", {
            state: {
              examId: examID,
              peerRole: peerRole,
            },
          });
        }
      });
  }
  useEffect(() => {
    // Find the corresponding saved answer in examQuestions array
    const savedAnswer = examQuestions.find(
      (item) => item.unique_id === currentItem.unique_id
    );
    // If there is a saved answer, set it as the initial value for answers state
    if (savedAnswer) {
      setAnswers(savedAnswer.user_ans);
    } else {
      // If there is no saved answer, reset answers state
      setAnswers([]);
    }
  }, [currentItem]);

 

  useEffect(()=>{
    if (currentItem && currentItem.type === 'cross word puzzle') {
      const grid = currentItem.options.map(row => (
        row.map(cell => ({
          clueID:cell.clueID ||"",
          clueNumber:cell.clueNumber,
          cluevalue: '',
          disabled: cell.cluevalue === '',
        }))
      ));
      setAnswers(grid);
       // Extracting across and down clues
       const acrossClues = currentItem.left.map(clueObj => ({
        id: clueObj.id,
        number: clueObj.number,
        clue: clueObj.clue,
        answer:''
      }));
      const downClues = currentItem.right.map(clueObj => ({
        id: clueObj.id,
        number: clueObj.number,
        clue: clueObj.clue,
        answer:''
      }));
  
      setCluesAcross(acrossClues);
      setCluesDown(downClues);
    }
  
  },[currentItem])
  

  useEffect(()=>{
    if(error){
      setTimeout(() => {
        setError("")
      }, 3000);
    }

  },[error])

  useEffect(()=>{
    if(closing){
      setTimeout(() => {
        setClosing(false)
      }, 1000);
    }
    },[closing])
  
  

  useEffect(() => {
    console.log(examQuestions);
  }, [examQuestions]);

  useEffect(() => {
    if (array && array.length > 0) {
      // const currentItem = array[currentIndex];
      setCurrentItem(array[currentIndex]);
    }
  }, [array, currentIndex]);

  useEffect(() => {
    if (examID !== undefined) {
      examsGet();
    }
  }, [examID]);

  return (
    <div className={style.Container}>
      {validateLoader ? (
        <div className={style.validateLoaderDiv}>
          <div className={style.validateLoaderText}>
            <p>Please Wait...</p>
          </div>
          <div className={style.validateLoaderContent}>
            <div className={style.LoaderCircle}></div>
          </div>
        </div>
      ) : (
        ""
      )}
      {whiteBoradIconShow  ? (
        <div className={style.chatboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )}

{whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''}
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>{examName}</p>
        </div>
        <div className={style.HeadButton}></div>
      </div>
      <div className={style.assesment}>
        {currentItem.id ? (
          <div className={style.AssessmentHead}>
            <div className={style.totalAssesment}>
              <div className={style.Count}>
                <span className={style.value}>
                  {" "}
                  Question no -{currentItem.id}/{array.length}
                </span>
              </div>
              <div
                className={style.assesmentQuestion}
                style={{
                  alignItems:
                    currentItem.type === "fill in the blanks" ? "normal" : "",
                }}
              >
                {currentItem.id}.
                {currentItem.type === "fill in the blanks" &&
                currentItem.generation === "admin" ? (
                  <div className={style.FillQuestion}>
                    {currentItem.question
                      .split(/\[blank \d+\]/)
                      .map((part, index) =>
                        index ===
                        currentItem.question.split(/\[blank \d+\]/).length -
                          1 ? (
                          <span
                            key={index}
                            dangerouslySetInnerHTML={{ __html: part }}
                          />
                        ) : (
                          <React.Fragment key={index}>
                            <span>{part}</span>
                            <input
                              type="text"
                              value={answers[index] || ""}
                              onChange={(e) => {
                                const newAnswers = [...answers];
                                newAnswers[index] = e.target.value;
                                setAnswers(newAnswers);
                              }}
                            />
                          </React.Fragment>
                        )
                      )}
                  </div>
                ) : currentItem.type === "fill in the blanks" ? (
                  <div className={style.FillQuestion}>
                    {currentItem.question.split(/_{2,}/).map((part, index) =>
                      index ===
                      currentItem.question.split(/_{2,}/).length - 1 ? (
                        <span
                          key={index}
                          dangerouslySetInnerHTML={{ __html: part }}
                        />
                      ) : (
                        <React.Fragment key={index}>
                          <span>{part}</span>
                          <input
                            type="text"
                            value={answers[index] || ""}
                            onChange={(e) => {
                              const newAnswers = [...answers];
                              newAnswers[index] = e.target.value;
                              setAnswers(newAnswers);
                            }}
                          />
                        </React.Fragment>
                      )
                    )}
                  </div>
                ) : currentItem.type === "Match the following" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentItem.question,
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentItem.question,
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  border:
                    currentItem.type === "multiple choices" ||
                    currentItem.type === "multiple choice" ||
                    currentItem.type === "Match the following" ||
                    (currentItem.type === "fill in the blanks" &&
                      currentItem.question.includes("__") &&
                      currentItem.options.length < 0)
                      ? "none"
                      : "",
                }}
                className={style.assesmentAnswer}
              >
                {currentItem.type === "multiple choices" ||
                currentItem.type === "multiple choice" ? (
                  currentItem.options.map((optionObj, index) => {
                    let optionValue;
                          if (typeof optionObj === 'string') {
                            optionValue = optionObj; // Direct string value
                          } else if (optionObj.option) {
                            optionValue = optionObj.option; // Nested within an object with "option" key
                          } else {
                            // If options are nested within objects with unique keys
                            const optionKey = Object.keys(optionObj)[0];
                            optionValue = optionObj[optionKey];
                          }
                    return(
                    <div key={index}>
                      <input
                        type="radio"
                        id={`option_${index}`}
                        name="assessmentOptions"
                        value={optionValue}
                        checked={answers.includes(optionValue)}
                        onChange={(e) => {
                          setAnswers([e.target.value]);
                        }}
                      />
                      <label htmlFor={`option_${index}`}>{optionValue}</label>
                    </div>
                  )})
                ) : currentItem.type === "fill in the blanks" &&
                  currentItem.question.includes("_") ? (
                  <></>
                ) : currentItem.type === "fill in the blanks" &&
                  !currentItem.question.includes("_") &&
                  currentItem.generation === "admin" ? (
                  <></>
                ) : currentItem.type === "fill in the blanks" &&
                  !currentItem.question.includes("_") &&
                  currentItem.generation !== "admin" ? (
                  <>
                    <textarea
                      onChange={(e) => {
                        setAnswers([e.target.value]);
                      }}
                      value={answers[0]}
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                    <div className={style.BottomIcons}></div>
                  </>
                ) : currentItem.type === "Match the following" ? (
                  <div className={style.DropContainer}>
                    <div className={style.DropQuestions}>
                      <table>
                        <tbody>
                          {currentItem.left
                            ? currentItem.left.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item}</td>
                                    <td
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleDrop(e, index)}
                                    >
                                      {answers[index] ? (
                                        <div className={style.DraggableItem}>
                                          {answers[index]}
                                        </div>
                                      ) : (
                                        <div
                                          className={style.DraggablePlaceholder}
                                        >
                                          {draggedItem === null &&
                                            "Drag Answer Here"}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                    <div className={style.DropAnswers}>
                      <h5>Answers</h5>
                      <div className={style.CardContainer}>
                        {currentItem.right
                          ? currentItem.right.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  draggable
                                  onDragStart={(e) => handleDragStart(e, item)}
                                  className={style.Card}
                                >
                                  <p>{item} </p>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                ):currentItem.type==="dictation"?(
                  <div >
                    <table className={style.DictationTabel}>
                      <tbody>
                        {currentItem.left.map((url,index)=>{
                          return(
                            <tr key={index}>
                          <td><HiSpeakerWave style={{color:currentPlayingIndex===index?"green":''}} className={style.speakerIcon} onClick={() => handleAudioClick(url,index)}/></td>
                          <td><input
                              type="text"
                              placeholder="Enter your answer"
                              value={answers[index] || ""}
                              onChange={(e) => {
                                const newAnswers = [...answers];
                                newAnswers[index] = e.target.value;
                                setAnswers(newAnswers);
                              }}
                              
                            /></td>
                        </tr>
                          )

                        })}
                        
                      </tbody>
                    </table>
                  </div>
                )
                 : currentItem.type === "Match the following with image" ? (
                  <div className={style.DropContainer}>
                    <div className={style.DropQuestions}>
                      <table>
                        <tbody>
                          {currentItem.left
                            ? currentItem.left.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td><div className={style.DropDownImage}>
                                    <img src={item} alt={`Option ${index + 1}`} style={{ width: "100px", height: "100px" }} />
                                      </div></td>
                                    <td
                                      onDragOver={(e) =>
                                        e.preventDefault()
                                      }
                                      onDrop={(e) => handleDrop(e, index)}
                                    >
                                      {answers[index] ? (
                                        <div
                                          className={style.DraggableItem}
                                        >
                                          {answers[index]}
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            style.DraggablePlaceholder
                                          }
                                        >
                                          {/* {draggedItem === null &&
                                            "Drag Answer Here"} */}
                                            {"Drag Answer Here"}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                    <div className={style.DropAnswers}>
                      <h5>Answers</h5>
                      <div className={style.CardContainer}>
                        {currentItem.right
                          ? currentItem.right.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  
                                  onDragStart={(e) =>
                                    handleDragStart(e, item)
                                  }
                                  className={style.Card}
                                >
                                  <p>{item} </p>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                ): currentItem.type === "cross word puzzle"? (
                  <div className={style.crosswordcontainer}>
                  <div className={style.crosswordpuzzle}>
                  {answers.map((row, rowIndex) => (
                    <div key={rowIndex} className={style.row}>
                      {row.map((cell, colIndex) => (
                        <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                          <input
                          ref={(input) => (inputRefs.current[rowIndex * answers[0].length + colIndex] = input)}
                            type="text"
                            maxLength="1"
                            className={`${style.cell} ${cell.disabled ? style.disabledInput : ''}`}
                            value={cell.value}
                            onChange={(e) => handleCellValueChange(rowIndex, colIndex, e.target.value,cell.clueID,cell.clueNumber)}
                            disabled={cell.disabled}
                          />
                          {cell.clueNumber && (  // Display clue number only if it exists
                            <div className={style.clueNumber}>{cell.clueNumber}</div>
                          )}
                        </div>
                      ))}
                    </div>
                    ))}
                </div>
                <div className={style.PuzzleQuestion}>
                  <div className={style.QuestionAcross}>
                  <h4>Across</h4>
                  {cluesAcross.map((clueObj, index) => (
                    <div key={index} className={style.QuestionAcrossInputContainer}>
                      <span>{clueObj.number}.{clueObj.clue} </span>
                      
                      <input type="text" value={clueObj.answer} readOnly/>
                    </div>
                  ))}
                  
                  </div>
                  <div className={style.QuestionDown}>
                  <h4>Down</h4>
                  {cluesDown.map((clueObj, index) => (
                      <div key={index} className={style.QuestionDownInputContainer}>
                        <span>{clueObj.number}.{clueObj.clue} </span>
                        <input type="text" value={clueObj.answer} readOnly/>
                        
                      </div>
                    ))}
                    
                    </div>
                </div>
                  </div>
                )  : (
                  <>
                    <textarea
                      onChange={(e) => {
                        setAnswers([e.target.value]);
                      }}
                      value={answers[0]}
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                    <div className={style.BottomIcons}>
                      {/* <span className={style.validate}>
                                <FaCheckCircle
                                onClick={()=>{
                                  if(answer!==""){
                                    handleCheckCircleClick()
                                  }
                                }}
                                  
                                />
                                <RiDeleteBin5Line
                                  onClick={() => {
                                    handleDeleteBinClick();
                                  }}
                                />
                              </span> */}
                    </div>
                  </>
                )}
              </div>

              {error&& (
                    <p className={style.Validtext}>*{error}</p>
                  )}
                  <div className={style.Requiredmessage}><p>(*Please save answer before question change)</p></div>

            </div>
            <div className={style.AddButton}>
              <>
                <button
                style={{backgroundColor:saveButtonClicked?"blue":""}}
                  onClick={() => {
                    if (answers.length > 0) {
                      handleSaveAnswer();
                      setSaveButtonClicked(true)
                    }
                    else{
                      setError("Please enter answer")
                    }
                  }}
                  className={style.SaveButton}
                >
                  Save Answer
                </button>

                {currentIndex === array.length - 1 ? (
                  <button
                    className={style.ValidateButton}
                    onClick={() => {
                      if (examQuestions.length > 0) {
                        examsValidation();
                        setValidateLoader(true);
                      }
                      else{
                        setError("Please enter answer")
                      }
                    }}
                  >
                    Finish Exam
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        handleNext();
                        setSaveButtonClicked(false)
                      }}
                      className={style.FinishButton}
                    >
                      Next Question
                    </button>
                    <button
                      onClick={() => {
                        handleSkip();
                        setSaveButtonClicked(false)
                      }}
                      className={style.SkipButton}
                    >
                      Skip
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
        ) : (
          <div>No Assessments in mapped yet </div>
        )}

        <div className={style.AssesssmentSide}>
          <div className={style.Sidecard}>
            <div className={style.CardHead}>Question Palette:</div>
            <div className={style.Set}>
              <p>Set :1</p>
            </div>
            <div className={style.Grid}>
              {numbers.map((number) => {
                // const validation = answerDetails.find(
                //   (item) => item.question_id === number
                // );
                // const backgroundColor =
                //   validation && validation.answer_is === "Correct" ? "green" : "red";

                return (
                  <span
                    key={number}
                    onClick={() => {
                      setAnswers([]);
                      setSaveButtonClicked(false)
                      setCurrentIndex(number - 1);
                    }}
                    className={style.span}
                    // style={{ backgroundColor }}
                  >
                    {number}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exams;
