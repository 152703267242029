import React,{useEffect,useState} from 'react'
import style from './Answer.module.css'
import { useLocation,useNavigate } from "react-router-dom";
import {baseurl} from '../Url'
import { HiSpeakerWave } from "react-icons/hi2";
const Answer = () => {

  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const location = useLocation();
  const state = location.state;
  const topicID=state.topicID
  const peerRole=state?.peerRole
  const aiID=state?.aiId
  const navigate=useNavigate()
  const[answer,setAnswer]=useState([])
  const[total,setTotal]=useState("")
  const[correct,setCorrect]=useState("")
  const[inCorrect,setIncorrect]=useState("")
  const[unAttended,setUnAttended]=useState("")
  const learneruserid=localStorage.getItem("learneruserid")
  const [userId, setUserID] = useState(learneruserid?learneruserid:USERID);
  const learnertoken=localStorage.getItem("learnertoken");
  const[token,setToken]=useState(learnertoken?learnertoken:key)
  const[totalMark,setTotalMark]=useState("")
  const[obtainedMark,setObtainedmark]=useState("")
  
  async function answerDetails() {
    await fetch(`${baseurl}/ai/assignment/summary`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: topicID,
        user_id: userId,
        ai_id:aiID
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnswer(data.data)
        if(data.data.length>0){
          setTotal(data.Total_questions[0].Total_questions)
        setCorrect(data.Correct_ans[0].Correct_ans)
        setIncorrect(data.Incorrect_ans[0].Incorrect_ans)
        setUnAttended(data.Skipped_questions[0].Skipped_questions)
        setTotalMark(data.Total_mark[0].Total_mark)
        setObtainedmark(data.Obtained_mark[0].User_Secured_mark)
        }
        
      });
  }
  useEffect(()=>{
    answerDetails()
  },[key])

  return (
    <div className={style.Container}>
        <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Assessment Summary</p>
        </div>
        <div className={style.HeaderButton} onClick={()=>{
          const source = peerRole !== undefined ? "support" : '';
          navigate("/learnings",{state:{
            peerRole:peerRole,source
          }})
        }}><button>Back to Learning</button></div>
        
      </div>
      <div className={style.Mark}>
        <div className={style.Marks}>
          {answer&&answer.length>0?
          <>
            <div style={{background:"#007bff"}} className={style.Markdiv}> 
            <div className={style.FirstText}>Total Mark</div>:&nbsp;
            <div className={style.SecondText}>{obtainedMark}/{totalMark}</div>
            </div>
            <div style={{background:"#007bff"}} className={style.Markdiv}>  <div className={style.FirstText}>Correct Answer</div>:&nbsp;
            <div className={style.SecondText}>{correct}</div>  </div>
           <div style={{background:"#e63720"}} className={style.Markdiv}><div className={style.FirstText}>Wrong Answer</div>:&nbsp;
            <div className={style.SecondText}>{inCorrect}</div>  </div>
           <div style={{background:"#c2af04"}} className={style.Markdiv}> <div className={style.FirstText}>Unattended</div>:&nbsp;
            <div className={style.SecondText}>{unAttended}</div></div>
          </>
          :''}
          
        </div>
      </div>
      <div className={style.Answer}>
        {answer&&answer.length>0?answer.map((item,index)=>{
          let newAnswer
          const answers = item.answer && item.answer.length > 0 ? item.answer.join(",") : "";
          const isNewFormat = item.answer && item.answer.length && Array.isArray(item.answer[0]);
          const groupByClueID = (arr) => {
            const grouped = {};
            arr.forEach((clues) => {
              clues.forEach((clue) => {
                if (clue.clueID in grouped) {
                  grouped[clue.clueID].push(clue.cluevalue);
                } else {
                  grouped[clue.clueID] = [clue.cluevalue];
                }
              });
            });
            return grouped;
          };
          newAnswer=(isNewFormat?<div>
            {Object.entries(groupByClueID(item.answer)).map(([clueID, values]) => (
              <p key={clueID}>
                {values} 
              </p>
            ))}
          </div>:answers)

          let questionContent;
          let optionsList;
      if (item.question_type === "fill in the blanks") {
        questionContent = (
          <div
            className={style.QuestionContent}
            dangerouslySetInnerHTML={{
              __html: `${item.question_id}.${item.question.replace(
                /\[blank (\d+)\]/g,
                '<input style="border:none;border-bottom: 1px dashed; padding-bottom: 2px;" type="text" >'
              )}`,
            }}
          />
        );
      }else if (item.question_type === "multiple choices") {
        optionsList = (
          <ul className={style.Options}>
            {item.options.map((option, index) => {
              let optionValue;
              if (typeof option === 'string') {
                optionValue = option; // Direct string value
              } else if (option.option) {
                optionValue = option.option; // Nested within an object with "option" key
              } else {
                // If options are nested within objects with unique keys
                const optionKey = Object.keys(option)[0];
                optionValue = option[optionKey];
              }
              return(
              <li key={index}>
                
                <span htmlFor={`optionValue${index}`}>{optionValue}</span>
              </li>
        )})}
          </ul>
        );
        questionContent = (
          <div
            className={style.QuestionContent}
            dangerouslySetInnerHTML={{ __html: `${item.question_id}.${item.question}` }}
          />
        );
      } else if (item.question_type === "Match the following") {
        questionContent = (
          <div>
            <div
              className={style.QuestionContent}
              dangerouslySetInnerHTML={{ __html: `${item.question_id}.${item.question}` }}
            />
           <div className={style.DropContainer}>
                          <div className={style.DropQuestions}>
                            <table>
                              <tbody>
                                {item.left
                                  ? item.left.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{item}</td>
                                          <td
                                            
                                          >
                                            
                                              <div
                                                className={
                                                  style.DraggablePlaceholder
                                                }
                                              >
                                                Drag Answer Here
                                              </div>
                                          
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div className={style.DropAnswers}>
                            <h5>Answers</h5>
                            <div className={style.CardContainer}>
                              {item.right
                                ? item.right.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={style.Card}
                                      >
                                        <p>{item} </p>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
          </div>
        );
      }  else if (item.question_type === "Match the following with image") {
        questionContent = (
          <div>
            <div
              className={style.QuestionContent}
              dangerouslySetInnerHTML={{ __html: `${item.question_id}.${item.question}` }}
            />
           <div className={style.DropContainer}>
                          <div className={style.DropQuestions}>
                            <table>
                              <tbody>
                                {item.left
                                  ? item.left.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td><img src={item} alt={`Option ${index + 1}`} style={{ width: "100px", height: "100px" }} /></td>
                                          <td
                                            
                                          >
                                            
                                              <div
                                                className={
                                                  style.DraggablePlaceholder
                                                }
                                              >
                                                Drag Answer Here
                                              </div>
                                          
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div className={style.DropAnswers}>
                            <h5>Answers</h5>
                            <div className={style.CardContainer}>
                              {item.right
                                ? item.right.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={style.Card}
                                      >
                                        <p>{item} </p>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
          </div>
        );
      }  else if (item.question_type === "dictation") {
        questionContent = (
          <div>
            <div
              className={style.QuestionContent}
              dangerouslySetInnerHTML={{ __html: `${item.question_id}.${item.question}` }}
            />
           <div >
                          <table className={style.DictationTabel}>
                            <tbody>
                              {item.left.map((url,index)=>{
                                return(
                                  <tr key={index}>
                                <td><HiSpeakerWave  className={style.speakerIcon}/></td>
                                <td><input
                                    type="text"
                                    placeholder="Enter your answer"
                                   
                                    disabled
                                  /></td>
                              </tr>
                                )

                              })}
                              
                            </tbody>
                          </table>
                        </div>
          </div>
        );
      }  else if (item.question_type === "cross word puzzle") {
        questionContent = (
          <div>
            <div
              className={style.QuestionContent}
              dangerouslySetInnerHTML={{ __html: `${item.question_id}.${item.question}` }}
            />
           <div >
            <div className={style.crosswordcontainer}>
                        <div className={style.crosswordpuzzle}>
                        {item.options.map((row, rowIndex) => (
                          <div key={rowIndex} className={style.row}>
                            {row.map((cell, colIndex) => (
                              <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                                <input
                                  type="text"
                                  maxLength="1"
                                  className={`${style.cell} ${cell.disabled ? style.disabledInput : ''}`}
                                  disabled={cell.disabled}
                                />
                                {cell.clueNumber && (  // Display clue number only if it exists
                                  <div className={style.clueNumber}>{cell.clueNumber}</div>
                                )}
                              </div>
                            ))}
                          </div>
                          ))}
                      </div>
                      <div className={style.PuzzleQuestion}>
                        <div className={style.QuestionAcross}>
                        <h4>Across</h4>
                        {item.left.map((clueObj, index) => (
                          <div key={index} className={style.QuestionAcrossInputContainer}>
                            <span>{clueObj.number}.{clueObj.clue} </span>
                            
                            <input type="text" readOnly/>
                          </div>
                        ))}
                        
                        </div>
                        <div className={style.QuestionDown}>
                        <h4>Down</h4>
                        {item.right.map((clueObj, index) => (
                            <div key={index} className={style.QuestionDownInputContainer}>
                              <span>{clueObj.number}.{clueObj.clue} </span>
                              <input type="text"  readOnly/>
                              
                            </div>
                          ))}
                          
                          </div>
                      </div>
                        </div>
                        </div>
          </div>
        );
      }  else {
        questionContent = (
          <div className={style.QuestionContent} dangerouslySetInnerHTML={{ __html: `${item.question_id}.${item.question}` }} />
        );
      }
      return (
        <div key={index} className={style.AnswerCard}>
          <div className={style.QuestionCount}>
            <span>
              {item.question_id}/{total}
            </span>
          </div>
          <div className={style.Question}>{questionContent}</div>
          {optionsList && (
            <div className={style.Options}>
              {optionsList}
            </div>
          )}
          <div className={style.Enterd}>
            <p>Your Answer: {newAnswer}</p>
          </div>
          <div style={{backgroundColor:item.answer_is==="Incorrect"?"#eb4034":""}} className={style.CorrectAnswer}>
            <span>{item.result}</span>
          </div>
        </div>
      );
    }):'No Answers '}
      </div>
    </div>
  )
}

export default Answer