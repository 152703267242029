import React,{useEffect,useState} from 'react'
import style from './TransactionHistory.module.css'
import Loader from '../Loder/Loader'
import {baseurl} from '../Url'
import Whiteboard from '../WhiteBoard/WhiteBoard';
import { BiClipboard } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";


const TransactionHistory = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");

  const[hisoryData,setHistoryData]=useState("")
  const[lodaing,setLoading]=useState(true)
  const[whiteBoradShow,setWhiteBoardShow]=useState(false)
  const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)

  const [closing, setClosing] = useState(false);

useEffect(()=>{
if(closing){
  setTimeout(() => {
    setClosing(false)
  }, 1000);
}
},[closing])




  async function hisoryGet() {
    await fetch(`${baseurl}/user/payment/history`, {
      method: "POST",
      body: JSON.stringify({
        user_id:USERID,
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setHistoryData(data.data)
          setLoading(false)
        }
      });
  }
  useEffect(()=>{
    hisoryGet()
  },[USERID])
  return (
    <div className={style.Container}>
      {lodaing?<Loader/>:''}
      {whiteBoradIconShow  ? (
        <div className={style.chatboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )}

{whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''}
      <div className={style.HistoryContent}>
        <table>
        <caption>Transaction History</caption>
          <tr>
            <th>No</th>
            <th>Amount</th>
            <th>Time</th>
            <th>Status</th>
            <th>Payment ID</th>
            <th>Currency</th>
          </tr>
          {hisoryData&&hisoryData.length>0?hisoryData.map((transaction,index)=>{
            const date=new Date(transaction.created_at?.$date)
            const formattedDate = date.toLocaleString();
            return(
              <tr key={index}>
            <td>{index+1}</td>
            <td>{transaction.amount}</td>
            <td>{formattedDate}</td>
            <td>{transaction.status==="captured"?"Success":"Failed"}</td>
            <td>{transaction.razorpay_payment_id}</td>
            <td>{transaction.currency}</td>
          </tr>
            )

          }):<div>{!lodaing?"No Transaction Found":""}</div>}
        </table>
      </div>
    </div>
  )
}

export default TransactionHistory