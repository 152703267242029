import React, { useState, useEffect } from "react";
import style from "./Opportunity.module.css";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import { FaRegCalendar } from "react-icons/fa6";

const Opportunity = () => {
  const navigate = useNavigate();

  const key = JSON.parse(localStorage.getItem("access-token"));
  const UserID = localStorage.getItem("USERID");

  const [opportunities, setOpportunities] = useState([]);

  async function opportunitiesGet() {
    await fetch(`${baseurl}/duno/opportunity/list`, {
      method: "Post",
      body: JSON.stringify({
        user_id: "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "opp");
        if (data.status === true) {
          setOpportunities(data.data);
        }
      });
  }

  useEffect(() => {
    opportunitiesGet();
  }, [UserID]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>DUNO Opportunity</p>
        </div>
      </div>
      <div className={style.body}>
        {opportunities && opportunities.length > 0
          ? opportunities.map((item, i) => {
              return (
                <div key={i}
                  className={style.card}
                  onClick={() => {
                    navigate("/opportunity/details",{
                      state:{
                        opportunity_id:item.opportunity_id
                      }
                    });
                  }}
                >
                  <div className={style.cardTop}>
                    <div className={style.cardTopLeft}>
                      <div className={style.cardHeading}>
                        <p>{item.opportunity_title}</p>
                      </div>
                      <div className={style.foundation}>
                        <p>{item.host_name}</p>
                      </div>
                      {/* <div className={style.location}>
                        <IoLocationOutline /> Kochi
                      </div> */}
                      <div className={style.date}>
                  <FaRegCalendar />
                  <p>
                    End Date:{" "}
                    <span>
                      {new Date(item.end_date.$date).toDateString()}
                    </span>
                  </p>
                </div>
                    </div>
                    <div className={style.cardTopRight}>
                      <div className={style.cardImage}>
                        <img
                          src={item.host_profile_image}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className={style.shortDescription}>
                    <p>
                      {item.opportunity_discription}
                    </p>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default Opportunity;
