import React, { useEffect, useState } from "react";
import style from "./OpportunityDetails.module.css";
import { IoLocationOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../Url";

const OpportunityDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const opportunity_id = state?.opportunity_id;

  const [opportunityData, setOpportunityData] = useState({});

  async function opportunityGet() {
    await fetch(`${baseurl}/duno/opportunity/single`, {
      method: "Post",
      body: JSON.stringify({
        opportunity_id: opportunity_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "opp");
        if (data.status === true) {
          setOpportunityData(data.data);
        }
      });
  }

  useEffect(()=>{
    if(opportunity_id!==undefined){
      opportunityGet()
    }

  },[opportunity_id])

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Full Stack Developer</p>
        </div>
      </div>
      <div className={style.body}>
            <div className={style.bodyHeader}>
            <div className={style.cardImage}>
                <img
                  src={opportunityData.host_profile_image}
                  alt=""
                />
              </div>
              <div className={style.cardHeading}>
                <p>{opportunityData.opportunity_title}</p>
              </div>
              <div className={style.foundation}>
                <p>{opportunityData.host_name}</p>
              </div>
              <div className={style.location}>
                <IoLocationOutline /> {opportunityData.location}
              </div>
            </div>

            <div className={style.description}>
                <div className={style.descriptionContent}> <p>
                    {opportunityData.opportunity_discription}
            </p></div>

            </div>
            <div className={style.jobDetails}>
               <p
                        dangerouslySetInnerHTML={{
                          __html: opportunityData.opportunity_details,
                        }}
                      ></p>

            </div>
            <div className={style.buttonSection}><button className={style.jobButton} onClick={()=>{
              navigate("/opportunity")
            }}>Back</button> <button className={style.applyButton}>Apply</button></div>
        </div>
    </div>
  );
};

export default OpportunityDetails;
