import React, { useState, useEffect } from "react";
import style from "./EventDetails.module.css";
import { useNavigate } from "react-router-dom";
import { IoMdTime } from "react-icons/io";
import { RiOrganizationChart } from "react-icons/ri";
import { MdOutlinePlace } from "react-icons/md";
import { baseurl } from "../Url";

function EventDetails() {
  const navigate = useNavigate();

  const [eventData, setEventData] = useState(null);
  const [showAttendModal, setShowAttendModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isUserAttending, setIsUserAttending] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fetchEventData = async () => {
    const eventId = localStorage.getItem("eventId");
    const userId = localStorage.getItem("USERID");

    try {
      const response = await fetch(`${baseurl}/event/details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: eventId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch event details");
      }

      const eventData = await response.json();
      setEventData(eventData.data);

      const attendeesResponse = await fetch(
        `${baseurl}/event/attendees/byevent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            event_id: eventId,
          }),
        }
      );

      if (!attendeesResponse.ok) {
        throw new Error("Failed to fetch attendees");
      }

      const attendeesData = await attendeesResponse.json();
      const attendees = attendeesData.data[0]; // Assuming attendees are in the first array element

      const userAttendee = attendees.find(
        (attendee) => attendee.user_id === userId
      );

      if (userAttendee) {
        const attendeeId = userAttendee._id.$oid;
        localStorage.setItem("attendeeId", attendeeId);
      }

      const isUserAttending = !!userAttendee;
      setIsUserAttending(isUserAttending);
    } catch (error) {
      console.error("Error fetching event details or attendees:", error);
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  const showSuccessSnackbar = (message) => {
    setSnackbarMessage(message);
    setShowSnackbar(true);

    setTimeout(() => {
      setShowSnackbar(false);
      setSnackbarMessage("");
    }, 2000);
  };

  const handleAttendClick = () => {
    setShowAttendModal(true);
  };

  const handleRegisteredClick = () => {
    setShowCancelModal(true);
  };

  const handleConfirmAttend = async () => {
    setShowAttendModal(false);

    const userName = localStorage.getItem("username");
    const userId = localStorage.getItem("USERID");
    const eventId = localStorage.getItem("eventId");
    const eventTitle = eventData.event_title;

    try {
      const response = await fetch(`${baseurl}/event/attendees`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_name: userName,
          user_id: userId,
          event_id: eventId,
          event_title: eventTitle,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to Register Event");
      }

      const result = await response.json();
      setIsUserAttending(true);
      showSuccessSnackbar("Event Registered successfully");

      // Reload attendees data after successful attendance
      fetchEventData(); // This will fetch the updated attendee list
    } catch (error) {
      console.error("Error Registering Event :", error);
    }
  };

  const handleConfirmCancel = async () => {
    setShowCancelModal(false);

    const userId = localStorage.getItem("USERID");
    const attendeeId = localStorage.getItem("attendeeId");

    try {
      const response = await fetch(`${baseurl}/event/attendees/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          attendee_id: attendeeId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to cancel event");
      }

      const result = await response.json();
      setIsUserAttending(false);
      showSuccessSnackbar("Event cancelled ");

      // Reload attendees data after successful cancellation
      fetchEventData(); // This will fetch the updated attendee list
    } catch (error) {
      console.error("Error cancelling Event :", error);
    }
  };

  const handleCancelModal = () => {
    setShowAttendModal(false);
    setShowCancelModal(false);
  };

  const AttendButton = () => (
    <button onClick={handleAttendClick}>Attend</button>
  );

  const RegisteredButton = () => (
    <button className={style.attending} onClick={handleRegisteredClick}>
      Registered
    </button>
  );

  const AttendModal = () => (
    <div className={style.modal}>
      <div className={style.modalContent}>
        <h2>Are you willing to attend this event?</h2>
        <div className={style.modalButtons}>
          <button className={style.modalCancel} onClick={handleCancelModal}>
            Cancel
          </button>
          <button className={style.modalConfirm} onClick={handleConfirmAttend}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );

  const CancelModal = () => (
    <div className={style.modal}>
      <div className={style.modalContent}>
        <h2>
          You Have Already Registered for This Event <br />
          Do you want to cancel this event?
        </h2>
        <div className={style.modalButtons}>
          <button className={style.modalCancel} onClick={handleConfirmCancel}>
            Yes
          </button>
          <button className={style.modalConfirm} onClick={handleCancelModal}>
            No
          </button>
        </div>
      </div>
    </div>
  );

  const Snackbar = () => {
    return (
      <div className={style.snackbar}>
        <p>{snackbarMessage}</p>
      </div>
    );
  };

  return (
    <div className={style.container}>
      {!eventData ? (
        <div className={style.loading}>
          <div className={style.spinner}></div>
        </div>
      ) : (
        <>
          <div className={style.header}>
            <div className={style.headerText}>
              <h1>{eventData.event_title}</h1>
              <p>
                Date :{" "}
                {new Date(
                  eventData.event_start_date.$date
                ).toLocaleDateString()}{" "}
                -{" "}
                {new Date(eventData.event_end_date.$date).toLocaleDateString()}
              </p>
              <p>Mode : {eventData.event_mode}</p>
            </div>
          </div>

          <div className={style.MainBox}>
            <div className={style.EventDetails}>
              <div className={style.box}>
                <div className={style.posterDetails}>
                  <div className={style.poster}>
                    <img src={eventData.event_image} alt="" />
                  </div>
                  <div className={style.discription}>
                    <div className={style.details}>
                      <h3>Details</h3>
                      <h4>{eventData.event_discription}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: eventData.event_details,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
                <div className={style.detailCards}>
                  <div className={style.actionContainer}>
                    <div className={style.dateBox}>
                      <div className={style.dateTime}>
                        <IoMdTime className={style.timeIcon} />
                        <p>Date & Time:</p>
                      </div>
                      <span>
                        {new Date(
                          eventData.event_start_date.$date
                        ).toLocaleString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}{" "}
                        -{" "}
                        {new Date(
                          eventData.event_end_date.$date
                        ).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}{" "}
                        IST
                      </span>
                    </div>
                  </div>
                  <div className={style.actionContainer}>
                    <div className={style.hostVenue}>
                      <div className={style.hostHead}>
                        <RiOrganizationChart />
                        <p>Hosted By:</p>
                      </div>
                      <span>{eventData.host_name}</span>
                    </div>
                  </div>
                  <div className={style.actionContainer}>
                    <div className={style.hostVenue}>
                      <div className={style.venueDetails}>
                        <MdOutlinePlace />
                        <p>Venue:</p>
                      </div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: eventData.event_venue,
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className={style.attendButton}>
                    {isUserAttending ? <RegisteredButton /> : <AttendButton />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showAttendModal && <AttendModal />}
      {showCancelModal && <CancelModal />}
      {showSnackbar && <Snackbar />}
    </div>
  );
}

export default EventDetails;
