import React,{useEffect,useState} from 'react'
import style from './Role.module.css'
import learnersLOgo from "../../assests/logo-white.png";
import { useNavigate,useLocation } from 'react-router-dom';
import Loader from '../Loder/Loader'
import {baseurl} from '../Url'

const Role = () => {

 const UserID=localStorage.getItem("USERID");
 const ClubID=localStorage.getItem("ClubID")
 const OrgID=localStorage.getItem("ORGID")
 const clickedItem=localStorage.getItem("clickedItem")
 const clickedItemDes=localStorage.getItem("clickeditemDes")
 const location=useLocation()
 const user=location.state?.user;
 const userRole=localStorage.getItem("registeraction")
 const navigate=useNavigate()
 const [roles,setRoles]=useState([]);
 const[loader,setLoader]=useState(false);
 const[clicked,setClicked]=useState(false)
 const[clikedRoleID,setClickedRoleId]=useState("")
  async function getRolles() {
    await fetch(`${baseurl}/role/get/all`, {
      method:"GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setRoles(data.data)
      });
  }
  async function setRole(role) {
    await fetch(`${baseurl}/map/role/user`, {
      method: "POST",
      body:JSON.stringify({
        user_id:UserID,
        role:role
      }),
      headers:{
        "Content-Type": "application/json",
      }
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          if(userRole==="normalregisteration"){
            navigate("/registration/success")
          }
          else if(userRole==="googleregisteration"){
            localStorage.setItem("mentor", data.data.is_mentor);
            localStorage.setItem("access-token", JSON.stringify(data.data.access_token));
            if(clickedItem!==undefined&&clickedItem!==null){
              navigate("/learnings")
            }
            else{
              navigate("/")
            }
          }
          setLoader(false)
        }
        
      });
  }
  async function learningGenerate() {
    await fetch(`${baseurl}/new/search/learning/mapping`, {
      method: "POST",
      body:JSON.stringify({
        user_id:UserID,
        club_id:ClubID,
        org_id:OrgID,
        name:clickedItem,
        des:clickedItemDes
      }),
      headers:{
        "Content-Type": "application/json",
      }
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setLoader(false)
          navigate("/learnings")
        }
        
      });
  }
  useEffect(()=>{
getRolles()
  },[])
  return (
    <div className={style.Container}>
      {loader?<Loader/>:''}
            {/* <div className={style.left}>
        <div className={style.LeftContent}>
          <div className={style.LogoImage}>
          <img src={learnersLOgo} />
          </div>
          <div className={style.heading}>
            <p>1 on 1 Training <br/>
            to reach goals</p>
          </div>
          <div className={style.HeadingText}>
            <p>As eLearning progressed, the ability to integrate elements such as images, videos, audio and graphics</p>
          </div>
        </div>

      </div> */}
      <div className={style.right}>
      <div className={style.LogoImage}>
              <img src={learnersLOgo} />
              </div>
                <div className={style.SearchText}><p>Learn Anything</p></div>
        <div className={style.Roles}>
            <p>Please Select Your Role</p>
            <div className={style.Cards}>
              {roles&&roles.length>0?roles.map((role,index)=>{
                return(
                  <div  key={index} onClick={()=>{
                    if(clicked===false){
                      setRole(role.role_name)
                      setLoader(true)
                      setClickedRoleId(role.role_id)
                      setClicked(true)
                    }
                    
                  }} style={{backgroundColor:clikedRoleID===role.role_id?"grey":''}} className={style.Card}>
                    <p>{role.role_name}</p>
                </div>
                )
              }):''}
            </div>
        </div>
      </div>
    </div>
  )
}

export default Role