import React, { useState, useEffect } from "react";
import style from "./Register.module.css";
import learnersLOgo from "../../assests/logo-white.png";
import facebook from "../../assests/Facebook_Logo_(2019).png.webp";
import google from "../../assests/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png";
import { AiOutlineInfoCircle, AiOutlineEye } from "react-icons/ai";
import { registerAction } from "../../actions/user";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backGroundimg from "../../assests/bg.jpg"
import { GoogleLogin,googleLogout} from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import{encryptJsonData} from '../EncryptionUtils'
import{getCountryCode} from '../CountryDetails'
import {baseurl} from '../Url'

const Register = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, SetPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(false);
  let { users } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const[gmail,setGmail]=useState("")
  const[googleUsername,setGoogleUsername]=useState("")
  const[message,setMessage]=useState("")
  const[conditionPopup,SetConditionPopup]=useState(false)
  const [isValid, setIsValid] = useState(true);
  const [phoneNumberError, setPhoneNumberError] = useState(true);
  const clickedItem=localStorage.getItem("clickedItem")
  const[callingCode,setCallingCode]=useState("")
  useEffect(()=>{
    if (callingCode === "") {
      getCountryCode().then((countryData) => {
        setCallingCode(countryData.callingCode);
      }).catch((error) => {
        console.log(error);
        
      });
    }
  },[callingCode])
  
  const handleRegister = (e) => {
    const data = {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Nickname: "",
      Email: email,
      DOB: "",
      Gender: "",
      ContactNumber: phoneNumber,
      Username: username,
      Password: password,
      CommunicationAddress: "",
      CurrentAddress: "",
      PermanentAddress: "",
      BillingAddress: "",
      MemberRoles: "Intern",
      SignupSource: "web",
    };
    const newdata=JSON.stringify(data)
  const encryptstring=encryptJsonData(newdata)
    e.preventDefault();
    dispatch(registerAction(encryptstring));
  };
  useEffect(() => {
    if (users?.status === "success") {
      if(clickedItem!==undefined&&clickedItem!==null){
        navigate("/registration/success")
      }
      else{
      navigate("/roleselection",{state:{user:"normalregisteration"}})
      localStorage.setItem("registeraction","normalregisteration")
      }
      
    } else {
      console.log(users?.message);
      setMessage(users?.message)
    }
  }, [users]);
  async function googleLogin() {
  const body={
        Username:googleUsername,
        Email:gmail
  }
  const newdata=JSON.stringify(body)
  const encryptstring=encryptJsonData(newdata)

    await fetch(`${baseurl}/account/signup/new`, {
      method: "POST",
      body: JSON.stringify({
        keys:encryptstring
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status==="success"){
          localStorage.setItem("ClubID", data.data.club_id);
            localStorage.setItem("ORGID", data.data.org_id);
            localStorage.setItem("USERID", data.data.user_id);
            localStorage.setItem("username", data.data.username);
          if(clickedItem!==undefined&&clickedItem!==null){
            localStorage.setItem("mentor", data.data.is_mentor);
            localStorage.setItem("access-token", JSON.stringify(data.data.access_token));
            navigate("/")
          }
          else{
            localStorage.setItem("registeraction","googleregisteration")
          navigate("/roleselection")
          }
        }
        else if(data.status==="failed"){
          // navigate("/login")
         
          setMessage("Email already Registred")
          googleLogout()
        }
       
      });
  }

 

  useEffect(()=>{
    if(gmail!==""&&googleUsername!==""){
      googleLogin()
    }
      },[gmail])

      const validateEmail = (email) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(email);
      };
    
      const handleEmailChange = (e) => {
        const newEmail = e;
        setIsValid(validateEmail(newEmail));
      };
      const validatePhonenumber=(number)=>{
        const numberRegex = /^\d{10}$/;
        return numberRegex.test(number)
      }
      const handlePhoneNumberChange=(number)=>{
        const newnumber=number;
        setPhoneNumberError(validatePhonenumber(newnumber))
      }

  return (
    <div className={style.Container}>
      {conditionPopup?
      <div className={style.PasswordPopup}>
      <div className={style.content}>
        <ul>
        <p>Password must include:</p>
          <li>6-15 Characters</li>
          <li>At least 1 capital letter</li>
          <li>At least 1 number</li>
          <li>At least 1 special character</li>
          <li>No spaces</li>
        </ul>
      </div>
      <div className={style.action}>
        <button
          onClick={() => {
           SetConditionPopup(false)
          }}
        >
          OK
        </button>
      </div>
    </div>
      :''}
      {/* <div className={style.left}>
        <div className={style.LeftContent}>
          <div className={style.LogoImage}>
          <img src={learnersLOgo} />
          </div>
          <div className={style.heading}>
            <p>1 on 1 Training <br/>
            to reach goals</p>
          </div>
          <div className={style.HeadingText}>
            <p>As eLearning progressed, the ability to integrate elements such as images, videos, audio and graphics</p>
          </div>
        </div>
      </div> */}
      <div className={style.right}>
      <div className={style.LogoImage}>
              <img src={learnersLOgo} />
              </div>
                <div className={style.SearchText}><p>Learn Anything</p></div>
      <div className={style.Register}>
      <div className={style.HeaderContent}>
            <div className={style.formText}>
              <p className={style.text1}>Sign Up</p>
            <p className={style.text2}>1o1LearnersClub</p></div>

          </div>
        <form className={style.form} >
        <div className={style.Google}>
            <GoogleLogin
              onSuccess={credentialResponse => {
               var decode=jwt_decode(credentialResponse.credential)
                console.log("success");
                setGmail(decode.email)
                setGoogleUsername(decode.name)
                  }}
                 onError={() => {
                      console.log('Login Failed');
                        }}
                     />
            </div>
            <div className={style.Bottemtext} >
              <p className={style.bottemtext1}>OR</p>
            </div>
          <input
            className={style.Input}
            value={username}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            type="text"
            required
            placeholder="Username"
          />
          <div className={style.Number}>
            <input placeholder="+91" maxLength={3} value={callingCode} onChange={(e)=>{
              setCallingCode((e.target.value))
            }} className={style.NumberInput}/>
            <input
              className={style.Input}
              type="text"
              value={phoneNumber}
              required
              onChange={(e) => {
                SetPhoneNumber(e.target.value);
              }}
              placeholder="Mobile Number"
            />
          </div>
          {phoneNumberError ? null : <p className={style.ErrorText}>Phone number should contain only numbers</p>}
          <input
            onClick={()=>{
              if (phoneNumber!=="") {
                handlePhoneNumberChange(phoneNumber)
              }
            }}
            value={email}
            onChange={(e)=>{
              setEmail(e.target.value)
            }}
            className={style.Input}
            required
            type="email"
            placeholder="Email"
          />
           {isValid ? null : <p className={style.ErrorText}>Invalid email format</p>}
          <div className={style.password}>
            <div onClick={()=>{
              SetConditionPopup(true)
            }} className={style.info}>
              <AiOutlineInfoCircle />
            </div>
              
            <input
             onClick={()=>{
              if(email!==""){
                handleEmailChange(email)
              }
             }}
              className={style.passwordInput}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
              type={type ? "password" : "text"}
              placeholder="Password"
            />
            <div
              className={style.eye}
              onClick={() => {
                type ? setType(false) : setType(true);
              }}
            >
              <AiOutlineEye />
            </div>
          </div>
          {passwordCheck?(
            password.length<6&& password!==""?(
              <p className={style.passwordError}>
              Password does not match criteria
            </p>
            ):password && password.length > 15 && password !== "" ? (
              <p className={style.passwordError}>
                Password does not match criteria
              </p>
            ):/\d/.test(password) === false && password !== "" ? (
              <p className={style.passwordError}>
                Password does not match criteria
              </p>
            ) : /[A-Z]/.test(password) === false && password !== "" ? (
              <p className={style.passwordError}>
                Password does not match criteria
              </p>
            ) :""
          ):''}

          <input
            className={style.Input}
            onClick={()=>{
             setPasswordCheck(true)
            }}
            type="password"
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            required
          />
          
          {message!==""? (
            <div className={style.RegisterError}>
              <p>{message}</p>
            </div>
          ) : (
            ""
          )}
          {password === confirmPassword &&
          password.length > 6 &&
          password.length < 15 &&
          /\d/.test(password) &&
          /[A-Z]/.test(password)&&
          username!==""&&
          email!==""&&
          phoneNumber!==""&&
          validateEmail(email)&&
          validatePhonenumber(phoneNumber)
           ?
           <button  onClick={(e)=>{
            e.preventDefault()
                handleRegister(e)
          }} className={style.formButton}>
            SignUp
          </button>
          :
          <button  disabled className={style.buttonDisabled}>
            SignUp
          </button>
          
          }
          
          <span className={style.AccountText}>
            Already have an Account? <a href="/login">Login</a>
          </span>
        </form>
      </div>
      </div>
      
    </div>
  );
};

export default Register;
