import React,{useEffect,useState} from 'react'
import style from './AccountValidation.module.css'
import learnersLOgo from "../../assests/logo-white.png";
import { useLocation,useNavigate } from 'react-router-dom';
import {baseurl} from '../Url'
const AccountValidation = () => {
  const location=useLocation()
const navigate=useNavigate()
const searchParams = new URLSearchParams(location.search);
const token = searchParams.get('token');
const[validate,setValidate]=useState(false)
async function validateToken() {
  await fetch(`${baseurl}/validate_invite/token`, {
    method: "POST",
    body: JSON.stringify({
      token:token
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
         setValidate(true)
      }
    });
}
useEffect(()=>{
  validateToken()
 },[token])
  return (
    <div className={style.Container}>
        <div className={style.Content}>
          
        <div className={style.Logo}>
                <img src={learnersLOgo}/>
            </div>
            <div className={style.SearchText}><p>Learn Anything</p></div>
            <div className={style.ValidationContent}>
              {validate?
              <>
                <div className={style.ValidationText}><p>Thanks for joining with us 1o1LearnersClub<br/>Your Account is activated <br/>Please click the button and login...
                </p></div>
                <div className={style.ValidationButton}>
                <button onClick={()=>{
                  console.log("clicked");
                  navigate("/login")
                }}>Login</button>
            </div>
              </>:
              <div className={style.ValidationText}><p>Invalid User
              </p></div>
              }
              
                
            </div>
           
        </div>
    </div>
  )
}

export default AccountValidation