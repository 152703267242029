import React, { useEffect, useState } from "react";
import style from "./MobileInsights.module.css";
import { baseurl } from "../Url";
import { PiExam } from "react-icons/pi";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { Bar } from "react-chartjs-2";

const MobileInsights = () => {
  const path = window.location.pathname;
  const splitPath = path.split("/");
  const learningId = splitPath[3];
  const currentTopicId = splitPath[4];
  const userId = splitPath[5];
  const adminStatus = splitPath[6];

  console.log(learningId);
  console.log(currentTopicId);
  console.log(adminStatus);
  console.log(userId);

  const [organizationId, setOrganizationId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [mainTopicId, setMainTopicId] = useState("");
  const [analyticsData, setAnalyticsData] = useState([]);
  const [clubId, setClubId] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [aId, setAId] = useState("");

  async function userLearningDetail() {
    await fetch(`${baseurl}/userlearning/details/individual`, {
      method: "POST",
      body: JSON.stringify({
        userlearning_id: learningId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrganizationId(data.data[0].Organization_ID);
        setMainTopicId(data.data[0].Topics[0].topic_id);
        const matchingTopic = data.data[0].topics.find(
          (topic) => topic.id === currentTopicId
        );
        console.log(data.data[0].topics);
        if (matchingTopic) {
          setTopicId(matchingTopic.id);
        }
        lessonDetail(
          data.data[0].Organization_ID,
          data.data[0].Topics[0].topic_id,
          matchingTopic.id,
          matchingTopic.value
        );
      });
  }

  async function lessonDetail(org_id, main_id, topic_id, topic_name) {
    console.log(org_id, main_id, topic_id, topic_name);
    await fetch(`${baseurl}/ai/lesson/generation/for/user`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: org_id,
        topic_id: topic_id,
        topic_name: topic_name,
        learning_id: learningId,
        main_topic_id: main_id,
        user_id: userId,
        admin_mapped: adminStatus,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAId(data.data[0]?.ai_id);
      });
  }

  async function getLearningLevel(id) {
    await fetch(`${baseurl}/topic/dashboard/learning_level`, {
      method: "POST",
      body: JSON.stringify({
        ai_id: aId,
        user_id: userId,
        main_topic_id: mainTopicId,
        topic_id: topicId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setGraphData(data.data);
      });
  }

  async function userDetail() {
    await fetch(`${baseurl}/user/detail/get `, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubId(data.data[0].club_id);
      });
  }

  async function analyticsGet(id) {
    await fetch(`${baseurl}/topic/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        org_id: organizationId,
        user_id: userId,
        club_ids: clubId,
        main_topic_id: mainTopicId,
        topic_id: topicId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnalyticsData(data.data[0].Learner_Engagement);
      });
  }

  const data = {
    labels: ["Topic Completion", "Assessment Completion"],
    datasets: [
      {
        label: "Percentage",
        backgroundColor: ["#007bff", "#007bff"], // Blue and Green colors for bars
        data: graphData.map((item) => {
          if (item.topic_completion_percentage) {
            return item.topic_completion_percentage.y;
          } else if (item.assesments_completion_percentage) {
            return item.assesments_completion_percentage.y;
          }
        }), // Y values for Topic Completion and Assessment Completion respectively
        barThickness: 70,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        max: 100, // Since the y values are percentages, set max value to 100
        min: 0,
        stepSize: 10, // Set the step size to 10 for clarity
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          callback: function (value) {
            return value + "%"; // Append '%' to the tick value
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    },
  };

  useEffect(() => {
    if (learningId) {
      userLearningDetail();
    }
  }, [learningId]);

  useEffect(() => {
    if (userId) {
      userDetail();
    }
  }, [userId]);

  useEffect(() => {
    if (topicId) {
      analyticsGet();
    }
  }, [topicId]);
  useEffect(() => {
    if (aId) {
      getLearningLevel();
    }
  }, [aId]);

  return (
    <div className={style.container}>
      <div className={style.insightPage}>
        {/* <div className={style.header}>
          <p>Insights</p>
        </div> */}
        <div className={style.insightDetail}>
          <div className={style.insightGrid}>
            <div className={style.card}>
              <div className={style.cardLeft}>
                <PiExam />
              </div>
              <div className={style.cardRight}>
                <p>Assessment Attended</p>
                <span style={{ color: "#3d0a24" }}>:</span>
                <span>{analyticsData.Assesmnet_attend_count}</span>
              </div>
            </div>
            <div className={style.card}>
              <div className={style.ongoingTopicLeft}>
                <p>Ongoing Topic</p>
              </div>
              <span style={{ color: "#3d0a24" }}>:</span>
              <div className={style.ongoingTopicRight}>
                <p>{analyticsData.Ongoing_Topic}</p>
              </div>
            </div>
            <div className={style.card}>
              <div className={style.cardLeft}>
                <BsFillQuestionCircleFill />
              </div>
              <div className={style.cardRight}>
                <p>Assessment Attended</p>
                <span style={{ color: "#3d0a24" }}>:</span>
                <span>{analyticsData.query_asked_count}</span>
              </div>
            </div>
          </div>
          <div className={style.Graph}>
            <div className={style.graphData}>
              <Bar data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileInsights;
