import React,{ useState, useEffect,useCallback,useRef} from 'react'
import style from './Profile.module.css'
import { useNavigate,useLocation } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
import Learningimg from "../../assests/learnings.png"
import Events from "../../assests/events.png"
import Oppertunity from "../../assests/opportunity.png"
import Mentors from "../../assests/mentor-experts.png"
import ProfileImage from '../../assests/Profile-PNG-File.png'
import { MdGroup,MdPhone,MdMail } from "react-icons/md";
import { MdEventAvailable } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import useRazorpay from "react-razorpay";
import learnersLOgo from "../../assests/1o1LearnersClub-Logo1-100-×-100px-2-300x300.png";
import Loader from '../Loder/Loader';
import {AiOutlineInfoCircle,AiOutlineEye} from "react-icons/ai";
import{encryptJsonData} from '../EncryptionUtils'
import {baseurl} from '../Url'
import { PiPasswordBold } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { MdPhotoCamera } from "react-icons/md";
import PasswordResetIcon from "../../assests/change-password-icon.webp";
import TransactionHistoryIcon from "../../assests/history_icon.png"
import Whiteboard from '../WhiteBoard/WhiteBoard';
import { BiClipboard } from "react-icons/bi";


const Profile = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const UserName=localStorage.getItem("username")
  const key = JSON.parse(localStorage.getItem("access-token"));
  const mentor=localStorage.getItem("mentor")
  const USERID = localStorage.getItem("USERID");
  const OrgID=localStorage.getItem("ORGID") 
   const[details,setDetails]=useState({})
   const[learner,setLearner]=useState({})
   const[edit,setEdit]=useState(false)
   const[username,setUsername]=useState("")
   const[fullname,setFullName]=useState("")
   const[email,setEmail]=useState("")
   const[phone,setPhone]=useState("")
   const[about,setAbout]=useState("")
   const[experience,setExperience]=useState("")
   const[aboutExp,setAboutExp]=useState("")
   const[qualification,setQualification]=useState("")
  const[passwordReset,setPasswordReset]=useState(false)
  const[password,setPassword]=useState("")
  const[confiremPassword,setConfirmPassword]=useState('')
  const [passwordCheck, setPasswordCheck] = useState(false);
  const[passwordSuccess,setPasswordSuccess]=useState(false)
  const[profileImage,setProfileImage]=useState(false)
  const[image,setImage]=useState({ preview: '', data: '' })
  const[loading,setLoading]=useState(true)
  const [type, setType] = useState(true);
  const[conditionPopup,SetConditionPopup]=useState(false)
  const[imageCondition,setImageCondition]=useState(false)
  const[imageConditionText,setImageConditionText]=useState("")
  const[contactInfo,setContactInfo]=useState(false)
  const[contactDetails,setContactDetails]=useState({phone:"",email:""})
  const fileInputRef = useRef(null);
  const ClubID=localStorage.getItem("ClubID")

 const clubIdsArray = ClubID.split(',');

 const[whiteBoradShow,setWhiteBoardShow]=useState(false)
 const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)

  
  // async function mentorDetails() {
  //   await fetch(`${baseurl}/mentor/main/dashboard`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       user_id:USERID,
  //       org_id:OrgID
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ${key}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       setDetails(data.data[0])
  //     });
  // }
  // async function learnerDetails() {
  //   await fetch(`${baseurl}/learner/main/dashboard`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       user_id:USERID,
  //       org_id:OrgID
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ${key}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       setLearner(data.data[0])
  //     });
  // }
  async function profileUpdate(e) {
    e.preventDefault()
    const body={
      user_id:USERID,
        Username:username,
        Email:email,
        contact:phone,
        About:about,
        About_exp:aboutExp,
        Expirence:experience,
        full_name:fullname,
        qualification:qualification
    }
    const newdata=JSON.stringify(body)
   const encryptstring=encryptJsonData(newdata)
    await fetch(`${baseurl}/profile/edit/by/user`, {
      method: "POST",
      body: JSON.stringify({
        keys:encryptstring
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
       if(data.status===true){
        setEdit(false)
        userDetails()
       }
      });
  }
  async function resetPassword(e) {
    e.preventDefault()
    await fetch(`${baseurl}/password/change`, {
      method: "POST",
      body: JSON.stringify({
        user_id:USERID,
        new_pwd:password
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setPasswordReset(false)
          setPasswordSuccess(true)
        }
      });
  }



  useEffect(()=>{
    userDetails()
  },[key])
  useEffect(()=>{
    if(details.profile){
      setUsername(details.profile.Name)
      setPhone(details.profile.Contact)
      setEmail(details.profile.Email)
      setAbout(details.profile.About)
      setExperience(details.profile.Expirence)
      setAboutExp(details.profile.About_exp)
      setFullName(details.profile.Fullname)
      setQualification(details.profile.qualification)
    }

  },[details])

  async function userDetails() {
    const body={
      user_id:USERID,
        org_id:OrgID,
        club_id:clubIdsArray
    }
    const newdata=JSON.stringify(body)
    const encryptstring=encryptJsonData(newdata)
    await fetch(`${baseurl}/main/dashboard/all`, {
      method: "POST",
      body: JSON.stringify({
        keys:encryptstring
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDetails(data.data[0])
        setLoading(false)
      });
  }

  async function imageUpload() {
    let formData = new FormData()
    formData.append('image', image.data);
    formData.append("user_id", USERID);
    await fetch(`${baseurl}/profile/pic/upload`, {
      method: "POST",
      body: formData,
      headers: {
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setProfileImage(false)
          userDetails()
        }
      });
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      // Check the file type
      if (file.type === "image/jpeg" || file.type === "image/png") {
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
  
          img.onload = () => {
            // Check the image dimensions
            if (img.width === 180 && img.height === 180) {
              // Image is 180x180 pixels
              const image = {
                preview: URL.createObjectURL(file),
                data: file,
              };
              setImage(image);
            } else {
              setImageCondition(true)
              setImageConditionText("Please choose the image with 180 *180 pixels")
            }
          };
        };
  
        reader.readAsDataURL(file);
      } else {
        setImageCondition(true)
        setImageConditionText("Please select a valid JPG or PNG image.")
      }
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const [closing, setClosing] = useState(false);

useEffect(()=>{
if(closing){
  setTimeout(() => {
    setClosing(false)
  }, 1000);
}
},[closing])

  

  return (
    <div className={style.Container}>
      {loading?<Loader/>:''}
      {whiteBoradIconShow  ? (
        <div className={style.chatboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )}

{whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''}
      {edit?
      <>
      <div
        className={style.ProfileOverlay}
        onClick={() => {
          setEdit(false)
        }}
      ></div>
      <div className={style.AddressSection}>
        <form action="" onSubmit={profileUpdate}>
        {/* <label>Profile Image</label>
          <input className={style.FileInput} type='file'/> */}
          <label>Full Name</label>
          <input
            type="text"
            name=""
            id=""
            value={fullname}
            onChange={(e)=>{
              setFullName(e.target.value)
            }}
            placeholder="FullName"
          />
          {/* <label>Email</label>
          <input
            type="text"
            name=""
            id=""
            readOnly
            value={email}
            onChange={(e)=>{
              setEmail(e.target.value)
            }}
            placeholder="Email"
          /> */}
          <label>Phone</label>
          <input
            type="text"
            name=""
            id=""
            value={phone}
            onChange={(e)=>{
              setPhone(e.target.value)
            }}
            placeholder="Phone"
          />
          <label>Experience</label>
          <input
            type="text"
            name=""
            id=""
            value={experience}
            onChange={(e)=>{
              setExperience(e.target.value)
            }}
            placeholder="Experience"
          />
          <label>Qualification</label>
          <textarea 
          value={qualification}
          onChange={(e)=>{
            setQualification(e.target.value)
          }}
          />
           <label>About </label>
          <textarea 
          value={about}
          onChange={(e)=>{
            setAbout(e.target.value)
          }}
          />
          <div className={style.FormButton}><button type="submit">Update</button></div>
        </form>
      </div>
    </>
      :''}
      {passwordReset?
      <>
      <div
        className={style.ProfileOverlay}
        onClick={() => {
          setPasswordReset(false)
        }}
      ></div>
      <div className={style.PasswordPopup}>
        <form action="" >
          <div className={style.Formtext}>
            <p>Please enter New Password</p>
          </div>
          <div className={style.password}>
              <div onClick={()=>{
                SetConditionPopup(true)
              }} className={style.info}>
                <AiOutlineInfoCircle/>
              </div>
              <input
                className={style.passwordInput}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordCheck(false);
                }}
                type={type?"password":"text"}
                placeholder="Password"
                required
              />
              <div
                className={style.eye}
                onClick={() => {
                  type ? setType(false) : setType(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </div>
            {passwordCheck?(
            password.length<6&& password!==""?(
              <p className={style.passwordError}>
              Password does not match criteria
            </p>
            ):password && password.length > 15 && password !== "" ? (
              <p className={style.passwordError}>
                Password does not match criteria
              </p>
            ):/\d/.test(password) === false && password !== "" ? (
              <p className={style.passwordError}>
                Password does not match criteria
              </p>
            ) : /[A-Z]/.test(password) === false && password !== "" ? (
              <p className={style.passwordError}>
                Password does not match criteria
              </p>
            ) :""
          ):''}
          <input
          onClick={()=>{
            setPasswordCheck(true)
           }}
            type="password"
            name=""
            placeholder="Confirm Password"
            value={confiremPassword}
            onChange={(e)=>{
              setConfirmPassword(e.target.value)
            }}
          />
          <div className={style.FormButton}>
          {password === confiremPassword &&
          password.length > 6 &&
          password.length < 15 &&
          /\d/.test(password) &&
          /[A-Z]/.test(password)?
            <button type="button" onClick={(e)=>{
                resetPassword(e)
            }}>Reset Password</button>
            :<button style={{backgroundColor:"#5e6194"}} type="button" disabled>Reset Password</button>}
          </div>
        </form>
      </div>
      </>
        :''
      }
      {passwordSuccess?
      <div className={style.SuccessPopup}>
      <div className={style.SuccessPopupText}>
        <p>Password changed Successfully </p>
      </div>
      <div className={style.SuccessPopupButton}>
        <button
          onClick={(e) => {
            setPasswordSuccess(false)
          }}
        >
          Ok
        </button>
        
      </div>
    </div>
      :''}
      {profileImage?
      <>
      <div
        className={style.ProfileOverlay}
        onClick={() => {
          setProfileImage(false)
        }}
      ></div>
      <div className={style.ProfilePopup}>
        <form action="" >
          <div className={style.Formtext}>
            <p>Please choose Your Image</p>
          </div>
          <input
            type="file"
            name=""
            id=""
            ref={fileInputRef}
            accept="image/png, image/jpeg"
            onChange={(e)=>{
              handleFileChange(e)
            }}
          />
          <div className={style.FormButton}>
            <button disabled={!image.data} type="button" onClick={()=>{
              imageUpload()
            }}>upload</button>
          </div>
        </form>
      </div>
      </>
      :''}
      {conditionPopup?
      <>
       <div
        className={style.ProfileOverlay}
        
      ></div>
        <div className={style.PasswordConditionPopup}>
        <div className={style.content}>
          <ul>
          <p>Password must include:</p>
            <li>6-20 Characters</li>
            <li>At least 1 capital letter</li>
            <li>At least 1 number</li>
            <li>At least 1 special character</li>
            <li>No spaces</li>
          </ul>
        </div>
        <div className={style.action}>
          <button
            onClick={() => {
             SetConditionPopup(false)
            }}
          >
            OK
          </button>
        </div>
      </div>
      </>
      :''}
      {imageCondition?
      <>
       <div
        className={style.ImageConditionOverlay}
      ></div>
        <div className={style.SuccessPopup}>
        <div className={style.SuccessPopupText}>
          <p>{imageConditionText}</p>
        </div>
        <div className={style.SuccessPopupButton}>
          <button
            onClick={() => {
              setImageCondition(false)
              resetFileInput()
            }}
          >
            Ok
          </button>
          
        </div>
      </div>
      </>
      :''}
      {contactInfo?
      <>
      <div
        className={style.ImageConditionOverlay}
      ></div>
      <div className={style.InfoDetails}>
        <div className={style.PopUpClose}>
        <AiOutlineClose onClick={()=>{
          setContactInfo(false)
        }}/>
        </div>
        <div className={style.InfoTextContainer}>
          <div className={style.InfoTextLeft}><MdPhone /><p>Phone</p><span>:</span></div>&nbsp;
          <div className={style.InfoTextRight}><p> {contactDetails?.phone}</p></div>
        </div>
        <div className={style.InfoTextContainer}>
          <div className={style.InfoTextLeft}><MdMail /><p>Email</p><span>:</span></div>&nbsp;
          <div className={style.InfoTextRight}><p>{contactDetails?.email}</p></div>
        </div>

      </div>
      </>
    :''}
      <div className={style.Content}>
        <>
        <div className={style.profile}>
        {details.profile?
              <>
              <div className={style.color}>
          <div className={style.profileimage}>
                <div onClick={()=>{
                  setProfileImage(true)
                }} className={style.ProfileImageDiv}>
                {details.profile.image ? (
      <img src={details.profile.image} />
    ) : (
      <img src={ProfileImage} />
    )}
             <div className={style.ImagePlaceholder}>
             <MdPhotoCamera />
             </div>
                  </div>
              </div>
       </div>
            <div className={style.ProfileDetails}>
              <div className={style.Edit}>
              <h2>{details.profile.Fullname}</h2>
            
              <div className={style.PasswordReset}>
                <GrEdit title="Edit" className={style.EditIcon} onClick={()=>{
                  setEdit(true)
                }} />
                <div title="Reset Password" className={style.PasswordIcon} onClick={()=>{
                  setPasswordReset(true)
                }}>
                  <img src={PasswordResetIcon}/>
                </div>
               {mentor !== "true"  ? (
            
              <div className={style.PasswordIcon} title="Transaction History" onClick={() => {
              navigate("/transactionhistory");
               }}><img src={TransactionHistoryIcon}/></div>
           
           ) : ""}
              </div>
              
              </div>
              
              <p>{details.profile.About}</p>
              <span onClick={()=>{
                setContactInfo(true)
                setContactDetails({phone:details.profile.Contact,email:details.profile.Email})
              }}>Contact info</span>

              {/* <div className={style.MainText}>
                <div className={style.FirstText}>Full Name</div>
                <div className={style.SecondText}>: {details.profile.Fullname} </div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>Phone</div>
                <div className={style.SecondText}>: {details.profile.Contact}</div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>Experience</div>
                <div className={style.SecondText}>: {details.profile.Expirence}</div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>About Experience</div>
                <div className={style.SecondText}>: {details.profile.About_exp}</div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>Email</div>
                <div className={style.SecondText}>: {details.profile.Email}</div>
              </div> */}
            </div>
              </>
              :''
            }
        </div>
            {mentor==="true"?
            <div className={style.Analytics}>
          <div className={style.AnalyticsHead}>
            <p>Analytics</p>
          </div>
          <div className={style.analyticContent}>
           <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                 
                  <MdGroup style={{fontSize:"1.75rem"}}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Total_leaners_mapped}</span> Assigned members</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                 
                  <MdEventAvailable style={{fontSize:"1.75rem"}}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.sessions}</span> Session Conducted</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Oppertunity}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>25</span>Member Goals Achieved </p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  
                  <GrMoney style={{fontSize:"1.75rem"}}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>25</span> Total Earnings /Hr</p>
            </div>
            </div>

          </div>
        </div>
        :
        <div className={style.Analytics}>
          <div className={style.AnalyticsHead}>
            <p>Analytics</p>
          </div>
          <div className={style.analyticContent}>
           <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Learningimg}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Total_Learnings_Attended}</span> learning Attended</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Mentors}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Total_Mentor_Assigned}</span> Mentors & Experts Assigned</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Oppertunity}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Opportunities_Used}</span> Opportunity used  </p>
            </div>
            </div>
            {/* <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Events}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.sessions}</span> Session Conducted</p>
            </div>
            </div> */}

          </div>

        </div>
}
        </>
      
        
      </div>
          

    </div>
  )
}

export default Profile