import React from 'react'
import style from './PasswordReset.module.css'
import { useEffect, useState } from "react";
import {
    AiOutlineInfoCircle,
    AiOutlineEye,
  } from "react-icons/ai";
  import { useLocation,useNavigate } from 'react-router-dom';
  import learnersLOgo from "../../assests/logo-white.png";
  import {baseurl} from '../Url'

const PasswordReset = () => {
    const [type, setType] = useState(true);
    const[conditionPopup,SetConditionPopup]=useState(false)
    const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState(false);
  const location=useLocation()
const navigate=useNavigate()
const searchParams = new URLSearchParams(location.search);
const token = searchParams.get('token');
const[validate,setValidate]=useState(false)
const[userId,setUserID]=useState("")

async function validateToken() {
  await fetch(`${baseurl}/validate_forgot_password/token`, {
    method: "POST",
    body: JSON.stringify({
      token:token
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
         setValidate(true)
         setUserID(data.data[0].user_id)
      }
    });
}
async function resetPassword(e) {
  e.preventDefault();
console.log("called");
  await fetch(`${baseurl}/password/change`, {
    method: "POST",
    body: JSON.stringify({
      user_id:userId,
      new_pwd:password
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        navigate("/login")
      }
    });
}


useEffect(()=>{
  validateToken()
 },[token])

  return (
    <div className={style.Container}>
    {conditionPopup?
    <div className={style.PasswordPopup}>
    <div className={style.content}>
      <ul>
      <p>Password must include:</p>
        <li>6-20 Characters</li>
        <li>At least 1 capital letter</li>
        <li>At least 1 number</li>
        <li>At least 1 special character</li>
        <li>No spaces</li>
      </ul>
    </div>
    <div className={style.action}>
      <button
        onClick={() => {
         SetConditionPopup(false)
        }}
      >
        OK
      </button>
    </div>
  </div>
    :''}
          
    <div className={style.right}>
    <div className={style.LogoImage}>
            <img src={learnersLOgo} />
            </div>
              <div className={style.SearchText}><p>Learn Anything</p></div>
              
      <div className={style.Login}>
        {validate?
      <>
        <div className={style.HeaderContent}>
            <div className={style.formText}>
              <p className={style.text1}>Reset Your Password</p></div>
  
          </div>
          <form className={style.form}  autoComplete="off">
      
            <div className={style.password}>
              <div onClick={()=>{
                SetConditionPopup(true)
              }} className={style.info}>
                <AiOutlineInfoCircle/>
              </div>
              <input
                className={style.passwordInput}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordCheck(false)
                }}
                type={type?"password":"text"}
                placeholder="New Password"
                required
              />
              <div
                className={style.eye}
                onClick={() => {
                  type ? setType(false) : setType(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </div>
            {passwordCheck?(
              password.length<6&& password!==""?(
                <p className={style.passwordError}>
                Password does not match criteria
              </p>
              ):password && password.length > 15 && password !== "" ? (
                <p className={style.passwordError}>
                  Password does not match criteria
                </p>
              ):/\d/.test(password) === false && password !== "" ? (
                <p className={style.passwordError}>
                  Password does not match criteria
                </p>
              ) : /[A-Z]/.test(password) === false && password !== "" ? (
                <p className={style.passwordError}>
                  Password does not match criteria
                </p>
              ) :""
            ):''}
            <input
              className={style.Input}
              onClick={()=>{
                setPasswordCheck(true)
              }}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              type="text"
              required
              placeholder="Confirm Password"
            />
            
            {password === confirmPassword &&
            password.length > 6 &&
            password.length < 15 &&
            /\d/.test(password) &&
            /[A-Z]/.test(password)
             ?
             <button  onClick={(e)=>{
             
             resetPassword(e)
            }} className={style.formButton}>
              Reset
            </button>
            :
            <button  disabled className={style.buttonDisabled}>
             Reset
            </button>
            
            }
          </form>
      </>
        :
        <div className={style.HeaderContent}>
        <div className={style.formText}>
              <p className={style.text1}>Invalid Token</p></div>
  
          </div>
        }
      </div>
    </div>
  </div>
  )
}

export default PasswordReset