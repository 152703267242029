import React, { useEffect, useState } from "react";
import style from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { MdClear, MdOutlineTopic } from "react-icons/md";
import Learningimg from "../../assests/learnings.png";
import Events from "../../assests/events.png";
import Oppertunity from "../../assests/opportunity.png";
import Mentors from "../../assests/mentor-experts.png";
import ProfileImage from "../../assests/Profile-PNG-File.png";
import { BsFillQuestionCircleFill, BsGrid3X3 } from "react-icons/bs";
import { MdEventAvailable } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { BiSearch } from "react-icons/bi";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Loader from "../Loder/Loader";
import { getCountryCode } from "../CountryDetails";
import { baseurl } from "../Url";
import { PiExam } from "react-icons/pi";
import Whiteboard from "../WhiteBoard/WhiteBoard";
import { BiClipboard } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

const Home = () => {
  const navigate = useNavigate();
  const key = JSON.parse(localStorage.getItem("access-token"));
  const CountryDetails = localStorage.getItem("countrydetails");
  const mentor = localStorage.getItem("mentor");
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const ClubID = localStorage.getItem("ClubID");

  const clubIdsArray = ClubID?.split(",");

  const [details, setDetails] = useState({});
  const [learner, setLearner] = useState({});
  const [posts, setPosts] = useState({});
  const [popup, setPopup] = useState(false);
  const [singleItem, setSingleItem] = useState({});
  const [eventType, setEventType] = useState("");
  const [postID, setPostID] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [mainTab, setMainTab] = useState(0);
  const [searchedItem, setSearcheditem] = useState({});
  const [loading, setLoading] = useState(true);
  const [suggestionData, setSuggestionData] = useState([]);
  const [requestNotSend, setRequestNotSend] = useState(true);
  const [requestSendid, setRequestSendId] = useState("");
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [whiteBoradShow, setWhiteBoardShow] = useState(false);
  const [whiteBoradIconShow, setWhiteBoardIconShow] = useState(true);
  const [closing, setClosing] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (search !== "") {
        postSearch();
        setLoading(true);
      }
    }
  };

  async function mentorDetails() {
    await fetch(`${baseurl}/mentor/main/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
        org_id: OrgID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDetails(data.data[0]);
      });
  }
  async function learnerDetails() {
    await fetch(`${baseurl}/learner/main/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
        org_id: OrgID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLearner(data.data[0]);
      });
  }
  async function feedData() {
    await fetch(`${baseurl}/posts/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPosts(data.data[0]);
        setLoading(false);
      });
  }

  async function singlePost(postid) {
    await fetch(`${baseurl}/posts/view`, {
      method: "POST",
      body: JSON.stringify({
        post_id: postid,
        org_id: OrgID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSingleItem(data.data[0]);
        setPostID(data.data[0].post_id);
        setEventType(data.data[0].type);
      });
  }
  async function oppertunityApply() {
    await fetch(`${baseurl}/posts/events/opertunities/apply`, {
      method: "POST",
      body: JSON.stringify({
        post_id: postID,
        user_id: USERID,
        type: eventType,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setSuccessPopup(true);
          setPopup(false);
        }
      });
  }

  async function postSearch() {
    await fetch(`${baseurl}/search/any`, {
      method: "POST",
      body: JSON.stringify({
        search: search,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        setSearcheditem(data.data);
        setSearchCompleted(true);
      });
  }
  async function suggestionDetails() {
    await fetch(`${baseurl}/user/suggestions`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
        org_id: OrgID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSuggestionData(data.data);
      });
  }

  async function requestSend(recipientid) {
    await fetch(`${baseurl}/connection_request/create`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        club_id: ClubID,
        sender_id: USERID,
        recipient_id: recipientid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setRequestNotSend(false);
          suggestionDetails();
        }
      });
  }

  async function analyticsGet() {
    await fetch(`${baseurl}/profile/main/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: USERID,
        club_ids: clubIdsArray,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnalyticsData(data.data[0].Learner_Engagement);
      });
  }

  useEffect(() => {
    if (key !== undefined) {
      if (mentor === "true") {
        mentorDetails();
      } else {
        learnerDetails();
      }
      suggestionDetails();
      feedData();
    }
  }, [key]);

  useEffect(() => {
    if (key === null || key === undefined) {
      navigate("/search");
    }
  }, []);

  useEffect(() => {
    if (CountryDetails === undefined || CountryDetails === null) {
      getCountryCode()
        .then((countryData) => {
          localStorage.setItem("countrydetails", true);
          localStorage.setItem("timezone", countryData.timezone);
          localStorage.setItem("currency", countryData.currency);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [CountryDetails]);
  useEffect(() => {
    if (search === "") {
      setSearchCompleted(false);
    }
  }, [search]);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  return (
    <div className={style.Container}>
      {loading ? <Loader /> : ""}
      {popup ? (
        <>
          <div
            onClick={() => {
              setPopup(false);
              setSingleItem({});
            }}
            className={style.PopupOverlay}
          ></div>
          <div className={style.Popup}>
            {/* <div className={style.PopupContent}> */}
            <div className={style.PopUpImage}>
              <img src={singleItem.image} />
            </div>
            <div className={style.Popuptext}>
              <h4>{singleItem.title}</h4>
              <p>{singleItem.description}</p>
            </div>
            <div className={style.PopupButton}>
              {singleItem.type === "event" ? (
                <>
                  <button
                    onClick={() => {
                      oppertunityApply();
                    }}
                  >
                    Attend
                  </button>
                  {/* <button><IoIosShareAlt/></button> */}
                </>
              ) : singleItem.type === "opportunity" ? (
                <>
                  <button
                    onClick={() => {
                      oppertunityApply();
                    }}
                  >
                    Apply
                  </button>
                  {/* <button><IoIosShareAlt/></button> */}
                </>
              ) : (
                <></>
              )}
            </div>
            {/* </div> */}
          </div>
        </>
      ) : (
        ""
      )}
      {whiteBoradIconShow ? (
        <div className={style.chatboticon}>
          <div
            onClick={() => {
              setWhiteBoardShow(true);
              setWhiteBoardIconShow(false);
            }}
            className={style.icontext}
          >
            <BiClipboard className={style.WhiteboardFloatingIcon} />
            <p> Whiteboard</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {whiteBoradShow ? (
        <div
          className={`${style.WhiteBoradContainer} ${
            closing ? style.closing : ""
          }`}
        >
          <div className={style.WhiteBoradHead}>
            <div className={style.HeadText}>White Board</div>
            <div
              className={style.WhiteBoradIcon}
              onClick={() => {
                setTimeout(() => {
                  setWhiteBoardIconShow(true);
                  setWhiteBoardShow(false);
                }, 500);
                setClosing(true);
              }}
            >
              <AiOutlineClose />
            </div>
          </div>
          <div className={style.WhiteBoradContent}>
            <Whiteboard />
          </div>
        </div>
      ) : (
        ""
      )}
      {successPopup ? (
        <div className={style.SuccessPopup}>
          <div className={style.SuccessPopupText}>
            <p>Successfully Applied</p>
          </div>
          <div className={style.SuccessPopupButton}>
            <button
              onClick={(e) => {
                setSuccessPopup(false);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={style.Content}>
        <div className={style.FirstContent}>
          <div className={style.SearchHead}>
            <div className={style.Search}>
              <BiSearch className={style.SearchIcon} />
              <input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onFocus={(e) => {
                  e.target.setAttribute(
                    "data-placeholder",
                    e.target.placeholder
                  );
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder =
                    e.target.getAttribute("data-placeholder");
                }}
                type="text"
                onKeyDown={handleKeyPress}
                placeholder="Search..."
              ></input>
              {search !== "" ? (
                <span
                  onClick={() => {
                    setSearch("");
                    setSearcheditem({});
                    setSearchCompleted(false);
                  }}
                >
                  <MdClear className={style.SearchIcon} />
                </span>
              ) : (
                ""
              )}
            </div>
            <button
              onClick={() => {
                postSearch();
                setLoading(true);
              }}
              disabled={search === ""}
            >
              Search
            </button>
          </div>
          <div className={style.FirstContentData}>
            {!search ? (
              <>
                <div className={style.Tabs}>
                  <Tabs
                    TabIndicatorProps={{
                      style: { backgroundColor: "#1a1e5d" },
                    }}
                    value={mainTab}
                    onChange={handleMainTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label="Learnings"
                      sx={{
                        "&.Mui-selected": {
                          // color: "white",
                          // backgroundColor: "#1a1e5d",
                          fontWeight: "bold",
                        },
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.7rem",
                          width: "100px",
                        },
                        fontSize: "1rem",
                        color: "#1a1e5d !important",

                        // backgroundColor: "#dbdbdb",
                        // width: "190px",
                        margin: "0 ",
                        borderRadius: "10px",
                      }}
                    />
                    <Tab
                      label="Events"
                      sx={{
                        "&.Mui-selected": {
                          // color: "white",
                          // backgroundColor: "#1a1e5d",
                          fontWeight: "bold",
                        },
                        fontSize: "1rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.7rem",
                          width: "100px",
                        },
                        color: "#1a1e5d !important",

                        // backgroundColor: "#dbdbdb",
                        // width: "190px",
                        margin: "0 ",
                        borderRadius: "10px",
                      }}
                    />
                    <Tab
                      label="Opportunities"
                      sx={{
                        "&.Mui-selected": {
                          // color: "white",
                          // backgroundColor: "#1a1e5d",
                          fontWeight: "bold",
                        },
                        fontSize: "1rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.7rem",
                          width: "100px",
                        },
                        color: "#1a1e5d !important",

                        // backgroundColor: "#dbdbdb",
                        // width: "190px",
                        margin: "0 ",
                        borderRadius: "10px",
                      }}
                    />
                    <Tab
                      label="Insights"
                      onClick={() => {
                        analyticsGet();
                      }}
                      sx={{
                        "&.Mui-selected": {
                          // color: "white",
                          // backgroundColor: "#1a1e5d",
                          fontWeight: "bold",
                        },
                        fontSize: "1rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.7rem",
                          width: "100px",
                        },
                        color: "#1a1e5d !important",

                        // backgroundColor: "#dbdbdb",
                        // width: "190px",
                        margin: "0 ",
                        borderRadius: "10px",
                      }}
                    />
                  </Tabs>
                </div>
                {/* <div className={style.Post}>
              <div className={style.Grid}>
                {posts&&posts.length>0?posts.map((post,index)=>{
                  return(
                    <div key={index} onClick={()=>{
                      setPopup(true)
                      singlePost(post.post_id)
                    }} className={style.PostCard}>
                <div className={style.PostCardImage}><img src={post.image} alt="Post" /></div>
                <p>{post.type}</p>
                <h4>{post.title}</h4>
                <p>{post.description}</p>
                </div>
                  )
                }):''}
                
              </div> 
            </div> */}
                <Typography>
                  {mainTab === 0 && (
                    <div>
                      {posts.post ? (
                        <div className={style.Grid}>
                          {posts.post && posts.post.length > 0
                            ? posts.post.map((post, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      setPopup(true);
                                      singlePost(post.id);
                                    }}
                                    className={style.PostCard}
                                  >
                                    <div className={style.PostCardImage}>
                                      <img src={post.image} alt="Post" />
                                    </div>
                                    {/* <p>{post.type}</p> */}
                                    <h4>{post.title}</h4>
                                    <p>{post.description}</p>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  {mainTab === 1 && (
                    <div>
                      {posts.Event ? (
                        <div className={style.Grid}>
                          {posts.Event && posts.Event.length > 0
                            ? posts.Event.map((post, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      setPopup(true);
                                      singlePost(post.id);
                                    }}
                                    className={style.PostCard}
                                  >
                                    <div className={style.PostCardImage}>
                                      <img src={post.image} alt="Post" />
                                    </div>
                                    {/* <p>{post.type}</p> */}
                                    <h4>{post.title}</h4>
                                    <p>{post.description}</p>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {mainTab === 2 && (
                    <div>
                      {posts.Oppertunity ? (
                        <div className={style.Grid}>
                          {posts.Oppertunity && posts.Oppertunity.length > 0
                            ? posts.Oppertunity.map((post, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      setPopup(true);
                                      singlePost(post.id);
                                    }}
                                    className={style.PostCard}
                                  >
                                    <div className={style.PostCardImage}>
                                      <img src={post.image} alt="Post" />
                                    </div>
                                    {/* <p>{post.type}</p> */}
                                    <h4>{post.title}</h4>
                                    <p>{post.description}</p>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  {mainTab === 3 && (
                    <div className={style.analytics}>
                      <div className={style.assessmentCount}>
                        <div className={style.assessmentIcon}>
                          <PiExam />
                        </div>
                        <div className={style.assessmentText}>
                          <span>{analyticsData.Assesmnet_attend_count}</span>
                          <p>Assessment Attended</p>
                        </div>
                      </div>
                      <div className={style.ongoingTopic}>
                        <div className={style.assessmentIcon}>
                          <MdOutlineTopic />
                        </div>
                        <div className={style.topicText}>
                          <span>{analyticsData.Ongoing_Topics}</span>
                          <p>Ongoing Topics</p>
                        </div>
                      </div>
                      <div className={style.ongoingTopic}>
                        <div className={style.assessmentIcon}>
                          <BsFillQuestionCircleFill />
                        </div>
                        <div className={style.topicText}>
                          <span>{analyticsData.query_asked_count}</span>
                          <p>Queries Asked</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Typography>
              </>
            ) : (
              <div className={style.SearchDiv}>
                <div className={style.Tabs}>
                  <Tabs
                    TabIndicatorProps={{
                      style: { backgroundColor: "#1a1e5d" },
                    }}
                    value={selectedTab}
                    onChange={handleTabChange}
                    centered
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label="Learnings"
                      sx={{
                        "&.Mui-selected": {
                          // color: "white",
                          // backgroundColor: "#1a1e5d",
                          fontWeight: "bold",
                        },
                        fontSize: "1rem",
                        color: "#1a1e5d !important",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.7rem",
                          width: "100px",
                        },
                        // backgroundColor: "#dbdbdb",
                        // width: "190px",
                        margin: "0 .5rem",
                        borderRadius: "10px",
                      }}
                    />
                    <Tab
                      label="Events"
                      sx={{
                        "&.Mui-selected": {
                          // color: "white",
                          // backgroundColor: "#1a1e5d",
                          fontWeight: "bold",
                        },
                        fontSize: "1rem",
                        color: "#1a1e5d !important",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.7rem",
                          width: "100px",
                        },
                        // backgroundColor: "#dbdbdb",
                        // width: "190px",
                        margin: "0 .5rem",
                        borderRadius: "10px",
                      }}
                    />
                    <Tab
                      label="Opportunities"
                      sx={{
                        "&.Mui-selected": {
                          // color: "white",
                          // backgroundColor: "#1a1e5d",
                          fontWeight: "bold",
                        },
                        fontSize: "1rem",
                        color: "#1a1e5d !important",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.7rem",
                          width: "100px",
                        },
                        // backgroundColor: "#dbdbdb",
                        // width: "190px",
                        margin: "0 .5rem",
                        borderRadius: "10px",
                      }}
                    />

                    {/* <Tab
                  label="Posts"
                  sx={{
                    "&.Mui-selected": {
                      // color: "white",
                      // backgroundColor: "#1a1e5d",
                      fontWeight: "bold",
                    },
                    fontSize: "1rem",
                    color: "#1a1e5d !important",
                    // backgroundColor: "#dbdbdb",
                    width: "180px",
                    margin: "0 .5rem",
                    borderRadius: "10px",
                  }}
                 
                /> */}
                  </Tabs>
                </div>
                <Typography>
                  {selectedTab === 0 && (
                    <div>
                      {searchedItem.learning ? (
                        <div className={style.Grid}>
                          {searchedItem.learning &&
                          searchedItem.learning.length > 0 ? (
                            searchedItem.learning.map((post, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setPopup(true);
                                    singlePost(post.post_id);
                                  }}
                                  className={style.PostCard}
                                >
                                  <div className={style.PostCardImage}>
                                    <img src={post.image} alt="Post" />
                                  </div>
                                  <p>{post.type}</p>
                                  <h4>{post.title}</h4>
                                  <p>{post.description}</p>
                                </div>
                              );
                            })
                          ) : search && searchCompleted ? (
                            <div className={style.ErrorMessage}>
                              <p>No match found</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {selectedTab === 1 && (
                    <div>
                      {searchedItem.Event ? (
                        <div className={style.Grid}>
                          {searchedItem.Event &&
                          searchedItem.Event.length > 0 ? (
                            searchedItem.Event.map((post, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setPopup(true);
                                    singlePost(post.id);
                                  }}
                                  className={style.PostCard}
                                >
                                  <div className={style.PostCardImage}>
                                    <img src={post.image} alt="Post" />
                                  </div>
                                  <p>{post.type}</p>
                                  <h4>{post.title}</h4>
                                  <p>{post.description}</p>
                                </div>
                              );
                            })
                          ) : search && searchCompleted ? (
                            <div className={style.ErrorMessage}>
                              <p>No match found</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  {selectedTab === 2 && (
                    <div>
                      {searchedItem.Oppertunity ? (
                        <div className={style.Grid}>
                          {searchedItem.Oppertunity &&
                          searchedItem.Oppertunity.length > 0 ? (
                            searchedItem.Oppertunity.map((post, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setPopup(true);
                                    singlePost(post.id);
                                  }}
                                  className={style.PostCard}
                                >
                                  <div className={style.PostCardImage}>
                                    <img src={post.image} alt="Post" />
                                  </div>
                                  <p>{post.type}</p>
                                  <h4>{post.title}</h4>
                                  <p>{post.description}</p>
                                </div>
                              );
                            })
                          ) : search && searchCompleted ? (
                            <div className={style.ErrorMessage}>
                              <p>No match found</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  {selectedTab === 3 && (
                    <div>
                      {searchedItem.post ? (
                        <div className={style.Grid}>
                          {searchedItem.post && searchedItem.post.length > 0 ? (
                            searchedItem.post.map((post, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setPopup(true);
                                    singlePost(post.post_id);
                                  }}
                                  className={style.PostCard}
                                >
                                  <div className={style.PostCardImage}>
                                    <img src={post.image} alt="Post" />
                                  </div>
                                  <p>{post.type}</p>
                                  <h4>{post.title}</h4>
                                  <p>{post.description}</p>
                                </div>
                              );
                            })
                          ) : search && searchCompleted ? (
                            <div className={style.ErrorMessage}>
                              <p>No match found</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={style.SecondContent}>
          {mentor === "true" ? (
            <div className={style.LoginedUser}>
              <div
                onClick={() => {
                  navigate("/profile");
                }}
                className={style.SuggestionCard}
              >
                {details.profile ? (
                  <>
                    <div className={style.ProfileIconDiv}>
                      <div className={style.ProfileIcon}>
                        <img src={ProfileImage} />
                      </div>
                    </div>
                    <div className={style.SuggestionContent}>
                      <div className={style.SuggestionName}>
                        <p>{details.profile.Name}</p>
                        <p style={{ color: "#878686" }}>
                          {details.profile.Email}
                        </p>
                      </div>
                      {/* <div className={style.SuggestionAction}>
            <p>Follow </p>
          </div> */}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className={style.LoginedUser}>
              <div
                onClick={() => {
                  navigate("/profile");
                }}
                className={style.SuggestionCard}
              >
                {learner && learner.profile_details ? (
                  <>
                    <div className={style.ProfileIconDiv}>
                      <div className={style.ProfileIcon}>
                        <img src={ProfileImage} />
                      </div>
                    </div>
                    <div className={style.SuggestionContent}>
                      <div className={style.SuggestionName}>
                        <p>{learner.profile_details.Name}</p>
                        <p style={{ color: "#878686" }}>
                          {learner.profile_details.Email}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          <div className={style.Suggestion}>
            <div className={style.SuggestionHead}>
              <p>Suggested For You</p>
            </div>
            <div className={style.SuggestionGrid}>
              {suggestionData && suggestionData.length > 0
                ? suggestionData.map((users, index) => {
                    return (
                      <div
                        onClick={() => {
                          navigate("/peerprofile", {
                            state: {
                              userID: users.id,
                              Role: users.role,
                            },
                          });
                        }}
                        key={index}
                        className={style.SuggestionCard}
                      >
                        <div className={style.ProfileIconDiv}>
                          <div className={style.ProfileIcon}>
                            <img src={ProfileImage} />
                          </div>
                        </div>
                        <div className={style.SuggestionContent}>
                          <div className={style.SuggestionName}>
                            <p>
                              {users.name}&nbsp;({users.role})
                            </p>
                          </div>
                          <div className={style.SuggestionAction}>
                            <p
                              onClick={(e) => {
                                e.stopPropagation();
                                requestSend(users.id);
                                setRequestSendId(users.id);
                              }}
                              style={{
                                cursor: "pointer",
                                color:
                                  !requestNotSend && requestSendid === users.id
                                    ? "grey"
                                    : "",
                              }}
                            >
                              Send Request
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
